import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DashboardBannerContainer,
  DashboardBannerWrapper,
  DashboardBannerInnerWrapper,
  DashboardBannerImageBlock,
  DashboardBannerTextBlock
} from './styled'
import headshotImage from '../../../../assets/media/images/bluePlasmaDrop.svg'
const DashboardBanner = ({ profileName }) => {
  const { t } = useTranslation('donorDashboardPage')

  return (
    <DashboardBannerContainer>
      <DashboardBannerWrapper>
        <div className='main-container'>
          <DashboardBannerInnerWrapper>
            <DashboardBannerImageBlock>
              <img src={headshotImage} alt='Dashboard Banner headshot' />
            </DashboardBannerImageBlock>
            <DashboardBannerTextBlock>
              <p>{t('welcome')}</p>
              <p>{profileName}</p>
            </DashboardBannerTextBlock>
          </DashboardBannerInnerWrapper>
        </div>
      </DashboardBannerWrapper>
    </DashboardBannerContainer>
  )
}

export default DashboardBanner
