import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useParams, useLocation } from 'react-router-dom'
import importer from 'common/importer'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const Breadcrumbs = importer('Components/Breadcrumbs')
const Loader = importer('Components/Loaders/LoaderSpinner')
const { getArrayFromTextCTAGrid, scrollToTop } = importer('Helpers/Utils', null)
const CentersActions = importer('Stores/Centers/Actions')
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants', null)

const LocationsPageContainer = ({
  locationsPageData,
  locationsPageMetaData,
  locationsPageLoading,
  getLocationsPage,
  fetchStateCenters,
  fetchSearchedCenters,
  searchedCentersLoading,
  searchedCenters,
  stateCenters,
  stateCentersLoading
}) => {
  const [breadcrumbsDrupalData, setBreadcrumbsDrupalData] = useState()
  const [breadcrumbsLevelTwoData, setBreadcrumbsLevelTwoData] = useState(null)
  const [finalData, setFinalData] = useState({})
  const params = useParams()
  const location = useLocation()
  const search = JSON.parse(new URLSearchParams(location.search).get('search'))
  /* 
  Scroll to the top when the component mounts
  */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (params.locationSlug && !search) {
      if (params.locationSlug.indexOf('-') > 0) {
        fetchStateCenters(params.locationSlug.split('-').join(' '))
      } else {
        fetchStateCenters(params?.locationSlug)
      }
    } else if (params.locationSlug && search) {
      fetchSearchedCenters(params.locationSlug)
    } else {
      // Default Location page call from drupal
      getLocationsPage(SLUG_TYPES.LOCATIONS)
    }
    setBreadcrumbsLevelTwoData(params.locationSlug)
  }, [location])

  useEffect(() => {
    if (stateCenters?.length === 0 && params?.locationSlug) {
      getLocationsPage(SLUG_TYPES.LOCATION_NOT_FOUND)
      window.localStorage.setItem('locationType', 'locationNotFound')
    } else {
      if (params.locationSlug) {
        // Compare to know if search param is a state or not. If state, then call the state page,
        // else call the 'locations/state' page to get the zipcode/city page data.
        if (search && searchedCenters?.length) {
          // Zipcode/city call from drupal
          getLocationsPage(SLUG_TYPES.LOCATIONS_STATE)
          window.localStorage.setItem('locationType', 'defaultLocation')
        } else if (!search && stateCenters?.length) {
          // State page call from drupal
          getLocationsPage(
            `locations/${params?.locationSlug
              ?.split(' ')
              ?.join('-')
              ?.toLowerCase()}`
          )
          window.localStorage.setItem('locationType', 'stateLocation')
        }
      }
    }
  }, [stateCenters, searchedCenters])

  useEffect(() => {
    let breadcrumbs
    breadcrumbs = locationsPageData?.contents?.find((item) => item.type === 'breadcrumb')
    setBreadcrumbsDrupalData(breadcrumbs)
    if (locationsPageData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(locationsPageData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setFinalData(dataWithTextCTAGrid)
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [locationsPageData])

  const containsNumber = (str) => /\d/.test(str)
  //checking if there is number with search param
  const searchHasNumber = search && containsNumber(params.locationSlug)
  // checkign if url has zipcode
  const pathHasNumber = params?.locationSlug && containsNumber(params.locationSlug)
  const shouldAddMetaTag = searchHasNumber || pathHasNumber

  useEffect(() => {
    // Returns the cleanup function to remove the meta tag from all other pages
    return () => {
      const metaTag = document.querySelector(`meta[name="robots"]`)
      if (metaTag && !search) {
        metaTag.remove()
      }
    }
  }, [search])
  return (
    <>
      {locationsPageLoading || stateCentersLoading || searchedCentersLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{locationsPageMetaData?.meta_title}</title>
            {shouldAddMetaTag ? (
              <meta name='robots' content='noindex, nofollow' />
            ) : (
              <meta name='description' content={`${locationsPageMetaData?.meta_description}`} />
            )}
            <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
          </Helmet>
          <Breadcrumbs
            clickableLevelOne={true}
            breadcrumbToggle={locationsPageMetaData?.field_content_breadcrumb}
            breadcrumbMobileToggle={locationsPageMetaData?.field_mobile_breadcrumb}
            levelOnePropsTitle={breadcrumbsDrupalData?.field_column_1_url?.title}
            levelTwoPropsTitle={breadcrumbsLevelTwoData}
          />
          {finalData?.contents?.map((component, index) => {
            if (component.type === 'breadcrumb' || (search && component.type === 'integration_content_component')) {
              return
            }
            return renderDrupalComponent(component, index)
          })}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  locationsPageData: state.publicContent?.getLocationsPageSuccess,
  locationsPageMetaData: state.publicContent?.getLocationsPageSuccess?.metadata,
  locationsPageLoading: state.publicContent?.getLocationsPageLoading,
  stateCenters: state.centers?.getStateCentersSuccess?.data,
  stateCentersLoading: state.centers?.getStateCentersLoading,
  searchedCenters: state.centers?.getDonorCentersAllSuccess?.data,
  searchedCentersLoading: state.centers?.getDonorCentersAllLoading
})

const mapDispatchToProps = (dispatch) => ({
  getLocationsPage: (slug) => dispatch(PublicContentActions.getLocationsPage(slug)),
  fetchSearchedCenters: (searchStr) => dispatch(CentersActions.getDonorCentersAll(searchStr, '', '', '', true)),
  fetchStateCenters: (stateName) => dispatch(CentersActions.getStateCenters(stateName))
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPageContainer)
