import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as HomeCenterIcon } from '../../../assets/media/icons/icon-star-home-center.svg'
import { ReactComponent as FavCenterIcon } from '../../../assets/media/icons/location-heart-icon.svg'
import { ReactComponent as LocationIcon } from '../../../assets/media/icons/icon-location-new-orange.svg'
import Modal from './../../Components/Modal'
import SimplePopup from '../ProfileSettings/Popups/SimplePopup/SimplePopup'
import { connect } from 'react-redux'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import ChangeHomeCenterPopup from '../Popups/ChangeHomeCenterPopup'
import importer from '@takedapdt/biolife-core/src/importer'
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const StyledCardContainer = styled.div`
  border-radius: 2.5rem;
  box-shadow: 0rem 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  background-color: var(--color-white);
  margin-bottom: 2rem;
  width: 32rem;
  ${devices.mobile} {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const StyledImageContainer = styled.div`
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  overflow: hidden;
  height: 14rem;
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const StyledAddressBox = styled.div`
  margin: 2.4rem 2rem 1.8rem 2rem;
  min-height: 8.8rem;
`

const StyledLocationNameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const StyledLocationName = styled.p`
  width: 85%;
  font-size: var(--caption-font-size);
  font-family: var(--font-family-bold) !important;
  color: var(--color-bl-blue);
  font-size: (--caption-font-size) !important;
`

const StyledLocationAddressDiv = styled.div`
  display: flex;
  margin: 0.8rem 0rem;
`

const LightBlueDiv = styled.p`
  background-color: var(--color-bg-light-blue);
  font-size: var(--caption-mobile-font-size);
  padding: 0.2rem 0.9rem;
  border-radius: 0.5rem;
  display: inline-block;
`

const StyledParagraph = styled.p`
  font-size: var(--caption-mobile-font-size);
  margin-left: 0.5rem;
  font-family: var(--font-family-regular) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex: 0.9;
`

const StyledCardFooter = styled.div`
  background-color: var(--color-bg-light-blue);
  width: 100%;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledFavCardFooter = styled.div`
  background-color: var(--color-bg-light-blue);
  width: 100%;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledChange = styled.p`
  text-decoration: underline;
  color: var(--color-bl-blue);
  cursor: pointer;
  font-size: var(--caption-font-size);
`
const StyledFluidButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* ${devices.mobile} {
    width: 100%;
  } */
`
const StyledRemove = styled.p`
  text-decoration: underline;
  color: var(--color-bl-blue);
  cursor: pointer;
  font-size: var(--caption-font-size);
`
const HomeCenterCard = ({
  isSpanishSupported,
  selectedCenterSFId,
  setselectedCenterSFId,
  setIsSpanishSupported,
  isPDN,
  currentCenter,
  center,
  donor,
  getDonorCentersAll,
  getDonorCentersAllLoading,
  favoriteCenterList,
  centers,
  updateFavoriteCenter,
  changeCheck,
  setUpdateFavCenterList,
  updateFavCenterListCheck,
  isHomeCenter
}) => {
  const { t, i18n } = useTranslation('UpdatePreferencesForm')
  const [simplePopUp, setSimplePopUp] = useState(false)
  const [newSelectedCenter, setNewSelectedCenter] = useState(null)
  const [showDiscardPopUP, setShowDiscardPopUP] = useState(false)
  const [centerToRemove, setCenterToRemove] = useState()

  useEffect(() => {
    if (changeCheck === 'Home-Center') {
      setNewSelectedCenter(centers?.find((center) => center.sfid === selectedCenterSFId[0]))
    } else if (changeCheck === 'Favorite-Center') {
      setNewSelectedCenter(centers?.find((center) => center.sfid === selectedCenterSFId))
    }
  }, [selectedCenterSFId])

  const updatedFavCenter = favoriteCenterList?.filter((favCenter) => favCenter.sfid !== center.sfid)
  const updatedFavCenterSFId = updatedFavCenter?.map((center) => {
    return center?.sfid
  })

  const handleRemoveFavoriteCenter = () => {
    updateFavoriteCenter(donor?.sfid, updatedFavCenterSFId)
    setShowDiscardPopUP(false)
    setUpdateFavCenterList(!updateFavCenterListCheck)
  }

  const discardPopUp_Data = {
    type: 'discardFavCenter',
    showTickIcon: false,
    showTitle: true,
    showsecondaryTitle: false,
    discardContent: true,
    showBiolifeImage: false,
    title: t('areYouSureToRemoveFavCenter') + center?.name + t('FavCenter'),
    buttonContent: t('yesRemoveFavCenter'),
    isFavCenter: true,
    centerToRemove: centerToRemove,
    handleRemoveFavoriteCenter: handleRemoveFavoriteCenter,
    internalPadding: '8.5rem 16.4rem'
  }
  return (
    <>
      <StyledCardContainer>
        <StyledImageContainer>
          <img
            src={newSelectedCenter?.webImageUrl || currentCenter?.webImageUrl}
            alt={newSelectedCenter?.imageAltText || currentCenter?.imageAltText}
          />
        </StyledImageContainer>
        <StyledAddressBox>
          <StyledLocationNameDiv>
            <StyledLocationName>{newSelectedCenter?.name || currentCenter?.name}</StyledLocationName>

            {(isHomeCenter || currentCenter?.isHomeCenter) && <HomeCenterIcon />}
            {!currentCenter?.isHomeCenter && changeCheck === 'Favorite-Center' && <FavCenterIcon />}
          </StyledLocationNameDiv>
          <StyledLocationAddressDiv>
            <LocationIcon style={{ marginTop: '0.5rem', width: '1.6rem', height: '1.6rem' }} />
            <StyledParagraph>
              {newSelectedCenter?.addressLine1 || currentCenter?.addressLine1} {newSelectedCenter?.city || currentCenter?.city},{' '}
              {newSelectedCenter?.stateCode || currentCenter?.stateCode} {newSelectedCenter?.zipcode || currentCenter?.zipcode}
            </StyledParagraph>
          </StyledLocationAddressDiv>
          {isSpanishSupported && <LightBlueDiv>{t('languageSupported')}</LightBlueDiv>}
        </StyledAddressBox>

        {(currentCenter?.isHomeCenter || changeCheck === 'Home-Center') && (
          <StyledCardFooter>
            <StyledParagraph>{t('yourHomeCenter')}</StyledParagraph>
            {!isPDN && <StyledChange onClick={() => setSimplePopUp(true)}>{t('change')}</StyledChange>}
          </StyledCardFooter>
        )}
        {!currentCenter?.isHomeCenter && changeCheck === 'Favorite-Center' && (
          <StyledFavCardFooter>
            <StyledRemove onClick={() => setShowDiscardPopUP(true)}>{t('removeFavCenter')}</StyledRemove>
          </StyledFavCardFooter>
        )}
        <Modal
          maxWidth={936}
          maxWidthMobile={'87.7%'}
          maxHeight={861}
          maxHeightMobile={580}
          isOpen={simplePopUp}
          onClose={() => {
            setSimplePopUp(false)
          }}
        >
          <ChangeHomeCenterPopup
            currentCenter={currentCenter}
            setIsSpanishSupported={setIsSpanishSupported}
            togglePOPUp={setSimplePopUp}
            selectedCenterSFId={selectedCenterSFId}
            setselectedCenterSFId={setselectedCenterSFId}
            donor={donor}
            changeCheck={'Home-Center'}
            getDonorCentersAllLoading={getDonorCentersAllLoading}
          />
        </Modal>
        <Modal
          maxWidth={836}
          maxWidthMobile={'87.7%'}
          maxHeight={461}
          maxHeightMobile={380}
          modalContent={discardPopUp_Data}
          isOpen={showDiscardPopUP}
          onClose={() => {
            setShowDiscardPopUP(false)
          }}
        >
          <SimplePopup
            modalContent={discardPopUp_Data}
            id={donor?.id}
            toggleDiscardPopup={setShowDiscardPopUP}
            favCenter={center}
            handleRemoveFavoriteCenter={handleRemoveFavoriteCenter}
          />
        </Modal>
      </StyledCardContainer>
    </>
  )
}
const mapStateToProps = (state) => ({
  donor: state.donors.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
  updateFavoriteCenter: (donorSfid, centerSfid) => dispatch(centersActions.updateFavoriteCenter(donorSfid, centerSfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeCenterCard)
