import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ReactComponent as CloseIcon } from '../../../assets/media/icons/button-close-large-orange.svg'
import bannerIcon from '../../../assets/media/brand/newbannerIcon.png'
import { isEmpty, lowerCase } from 'lodash'
import { devices } from '../../utils/styledComponentHelpers'
import { getCssColorFromDrupalColorType, getMediaFromDrupalMimeType } from '../../Constant/Utils'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider'
import PrimaryButton from '../Buttons/PrimaryButton'
import styled from 'styled-components'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'

const StyledBannerContainer = styled.div`
  background-color: ${(props) =>
    props.$bgColor === 'light_blue' ? 'var(--color-bg-blue)' : getCssColorFromDrupalColorType(props?.$bgColor)};
`

const StyledBannerDiv = styled.div`
  padding: 1.3rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: ${(props) => props.$clickable && 'pointer'};
  ${devices.mobile} {
    padding: 2.4rem;
  }
`
const StyledTextDiv = styled.div`
  padding-left: ${(props) => (props.$isIcon ? '1.6rem' : '0rem')};
  padding-right: 4rem;
  p {
    font-size: var(--caption-font-size);
    color: ${(props) => props.$textColor && getCssColorFromDrupalColorType(props?.$textColor)};
  }
  .ui.BlueUnderlineButton.button {
    font-size: var(--caption-font-size) !important;
    color: ${(props) => props.$textColor && getCssColorFromDrupalColorType(props?.$textColor)} !important;
  }
  ${devices.mobile} {
    padding: 0;
    padding-top: ${(props) => (props.$isIcon ? '8px' : '0px')};
    h4 {
      padding-bottom: 0.8rem;
    }
  }
`

const StyledIconDiv = styled.div`
  display: contents;
  img {
    width: 32px;
    height: 32px;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  margin: auto;
  cursor: pointer;
  width: 32px;
  height: 32px;
  path:last-child {
    fill: ${(props) => props.$textColor && getCssColorFromDrupalColorType(props?.$textColor)};
  }
  ${devices.mobile} {
    /* z-index: 802; */
    position: ${(props) => (props.$isIcon ? 'absolute' : '')};
    right: 2.4rem;
    width: 22px;
    height: 22px;
  }
`

const StyledLeftDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${devices.mobile} {
    flex-direction: column;
    align-items: start;
  }
`

const StyledRightDiv = styled.div``

// Stylings for smart banner same as before
const StyledSmartBanner = styled.div`
  &.smartbanner {
    left: 0;
    top: 0;
    width: 100%;
    height: 78px;
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    background: #fff;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    padding: 10px 0;
    z-index: 802;
    background-color: white;
  }
  .smartbanner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .smartbanner-close {
    display: block;
    font-family: 'ArialRoundedMTBold', Arial;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    // border-radius:14px;
    -webkit-font-smoothing: subpixel-antialiased;
    border: 0;
    width: 17px;
    height: 17px;
    line-height: 17px;
    color: #b1b1b3;
    // background:#efefef;
  }
  .smartbanner-close:active,
  .smartbanner-close:hover {
    color: #333;
  }
  .bannerImgIcon {
    width: 57px;
    height: 57px;
    border-radius: 7px;
    border: 0;
  }
  .smartbanner-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 275px;
    .smartbanner-title {
      font-size: 12px;
      line-height: 1.3em;
      color: #999;
      p {
        font-size: 15px;
        line-height: 20px;
        color: #000;
        font-weight: medium;
        margin: 0;
      }
    }
  }
  .smartbanner-button {
    // border-bottom:3px solid #b3c833;
    padding: 0 10px;
    min-width: 12%;
    height: 24px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    // font-weight:bold;
    color: $blue;
    // background-color:#b3c833;
    text-decoration: none;
    border-radius: 5px;
  }
  .smartbanner-button-text {
    padding: 0 5px;
  }
`

const Announcements = (props) => {
  const { getMessages, messages, donor, loggedInUser, facility, facilityLoading, drupalCoreData, setBannerHeight, style } = props
  const [visible, setVisible] = useState()
  const [alerts, setAlerts] = useState([])
  const [announcements, setAnnouncements] = useState([])
  const [promotions, setPromotions] = useState([])
  const [announcementStyles, setAnnouncementStyles] = useState([])
  const [showSmartBanner, setShowSmartBanner] = useState(true)
  const { t, i18n } = useTranslation('alertBanner')

  const { isMobileWidth, isTabletWidth } = useScreenSize()

  const { pathname } = useLocation()
  const location = pathname.split('/')

  /**
   * @description Handles banner close
   * @returns -- removes banner from FE and value from local storage
   */
  const handleDismiss = () => {
    setBannerHeight(0)
    setVisible(false)
    localStorage.setItem('showAlert', false)
  }

  useEffect(() => {
    getMessages()
  }, [i18n.language])

  useEffect(() => {
    setAnnouncementStyles(drupalCoreData?.filter((item) => item.type === 'notification_banner_component'))
  }, [drupalCoreData])

  useEffect(() => {
    !facilityLoading && setMessages()
  }, [messages, donor, pathname, facility, facilityLoading])

  useEffect(() => {
    if (localStorage.getItem('showAlert') === 'false') setVisible(false)
    else setVisible(true)
    if (localStorage.getItem('showSmartBanner') === 'false') setShowSmartBanner(false)
    else setShowSmartBanner(true)
  }, [window.localStorage.getItem('showAlert')])

  const filterByType = (list, type) => list.filter((item) => item.message_type__c === type && item.is_active__c)

  /**
   * @description gets messages depending on the centerID
   * @param {Object} centerID - The center sfid
   * @returns -- finds for any type of messages and returns them in respective message types(alerts, announcements, promotions)
   */
  const getMessagesByCenter = (centerID) => {
    const _messages = messages.find((item) => item.centerID === centerID)
    let _alerts = []
    let _announcements = []
    let _promotions = []
    if (_messages && _messages.list.length) {
      /* FILTER FOR ONLY PROMOTIONS | ALERTS | ACCOUNCEMENTS */
      const { list } = _messages
      _alerts = filterByType(list, 'Alerts')
      _announcements = filterByType(list, 'Announcements')
      _promotions = filterByType(list, 'Promotions')
    } else {
      //When the center has no message content
      const _message = messages.find((item) => item.centerID === null)
      if (!isEmpty(_message)) {
        const { list } = _message
        _alerts = filterByType(list, 'Alerts')
        _announcements = filterByType(list, 'Announcements')
        _promotions = filterByType(list, 'Promotions')
      }
    }
    return { _alerts, _promotions, _announcements }
  }

  /**
   * @description sets message types with the messages acording to the centerID
   * @param {Object} -- _alerts _promotions _announcements - The 3 message types
   * @returns -- sets the 3 message types
   */
  const updateMessageState = ({ _alerts, _promotions, _announcements }) => {
    setAlerts(_alerts)
    setAnnouncements(_announcements)
    setPromotions(_promotions)
  }

  /**
   * @description gets centerID and calls the updateMessageState
   * @returns -- returns the messages based on the centerID
   */
  const setMessages = () => {
    let defaultCenter = loggedInUser?.defaultCenter
    let centerID = defaultCenter ? defaultCenter.sfid : null

    if (messages && messages.length) {
      if (centerID) {
        if (!location?.[3] || lowerCase(location[2]) !== lowerCase(facility?.data?.state.split(' ').join('-'))) {
          updateMessageState(getMessagesByCenter(centerID))
        }
        // when navigate to homepage while logged in for the first time facility is undefined
        else if (facility?.data?.state === undefined) {
          updateMessageState(getMessagesByCenter(centerID))
        } else {
          updateMessageState(getMessagesByCenter(facility?.data?.sfid))
        }
      }
      if (!centerID) {
        if (!location?.[3] || lowerCase(location[2]) !== lowerCase(facility?.data?.state.split(' ').join('-'))) {
          updateMessageState(getMessagesByCenter(centerID))
        } else {
          updateMessageState(getMessagesByCenter(facility?.data?.sfid))
        }
      }
    }
  }

  /**
   * @description handling banner click for any links
   * @param {Object}  message - The current message visible on the screen
   * @returns -- opens the url on click if redirect_url__c is found
   */
  const handleBannerClickAction = (message) => {
    if (message.redirect_url__c) {
      window.open(message.redirect_url__c, '_blank')
    }
  }

  /**
   * @description renders the message banner on page
   * @param {Object}  message - The current message visible on the screen
   * @returns -- render the message banner with the passed message data
   */
  const renderMessage = (message) => {
    const activeAnnouncement = announcementStyles?.find((item) =>
      message?.message_type__c?.toLowerCase().includes(item?.field_notification_type)
    )
    setTimeout(() => {
      setBannerHeight(document.getElementsByClassName('announcements')[0]?.getBoundingClientRect().height)
    }, 20)
    return (
      <StyledBannerContainer $bgColor={activeAnnouncement?.field_bg_color}>
        <StyledBannerDiv className={'main-container announcements'}>
          <StyledLeftDiv>
            <StyledIconDiv>
              {getMediaFromDrupalMimeType(activeAnnouncement?.field_column_1_icon?.mimetype, activeAnnouncement?.field_column_1_icon?.url, activeAnnouncement?.field_column_1_icon?.alt)}
            </StyledIconDiv>
            <StyledTextDiv $isIcon={activeAnnouncement?.field_column_1_icon?.url} $textColor={activeAnnouncement?.field_text_color}>
              <p>
                {message && message?.description__c} &nbsp;
                {message && message?.redirect_url__c && (
                  <PrimaryButton text={t('learnMore')} variant={'blue_underline'} onClick={(e) => handleBannerClickAction(message)} />
                )}
              </p>
            </StyledTextDiv>
          </StyledLeftDiv>
          <StyledCloseIcon
            className='CloseContainer'
            $isIcon={activeAnnouncement?.field_column_1_icon?.url}
            $textColor={activeAnnouncement?.field_text_color}
            onClick={() => handleDismiss()}
          />
        </StyledBannerDiv>
      </StyledBannerContainer>
    )
  }

  const closeSmartBanner = () => {
    setShowSmartBanner(false)
    localStorage.setItem('showSmartBanner', false)
  }

  return (
    <div style={isMobileWidth || isTabletWidth ? null : style}>
      {(isMobileWidth || isTabletWidth) && showSmartBanner && (
        <StyledSmartBanner className='smartbanner'>
          <div className='smartbanner-container'>
            <div className='smartbanner-info'>
              <a href='javascript:;' onClick={closeSmartBanner} id='smb-close' className='smartbanner-close'>
                &times;
              </a>
              <img src={bannerIcon} alt='banner icon' className='bannerImgIcon' />
              <div className='smartbanner-title'>
                <p>{t('bioLifePlasmaSe')}</p>
                <div>{t('takedaPharma')}</div>
                {window.navigator.userAgent.toLowerCase().indexOf('android') !== -1 ? (
                  <span>
                    {t('get')} &ndash; {t('getOnthePlaySto')}
                  </span>
                ) : (
                  <span>
                    {t('get')} &ndash; {t('getOntheAppStor')}
                  </span>
                )}
              </div>
            </div>
            {window.navigator.userAgent.toLowerCase().indexOf('android') !== -1 && (
              <a href='https://play.google.com/store/apps/details?id=com.shire.biolife' target='_blank' className='smartbanner-button'>
                <span className='smartbanner-button-text'>{t('view')}</span>
              </a>
            )}
            {window.navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 && (
              // <a href="http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8" target="_blank" className="smartbanner-button">
              <a href='https://apps.apple.com/us/app/biolife-plasma-services/id1356589861' target='_blank' className='smartbanner-button'>
                <span className='smartbanner-button-text'>{t('view')}</span>
              </a>
            )}
          </div>
        </StyledSmartBanner>
      )}
      {visible &&
        (alerts.length > 0
          ? alerts.filter((message, index) => index === 0).map((message) => <Fragment key={message.id}>{renderMessage(message)}</Fragment>)
          : announcements.length > 0
          ? announcements
              .filter((message, index) => index === 0)
              .map((message) => <Fragment key={message.id}>{renderMessage(message)}</Fragment>)
          : promotions.length > 0
          ? promotions
              .filter((message, index) => index === 0)
              .map((message) => <Fragment key={message.id}>{renderMessage(message)}</Fragment>)
          : null)}
    </div>
  )
}

const mapStateToProps = (state) => ({
  messages: state.publicContent.messagesFiltered,
  donor: state.donors.donors?.data,
  loggedInUser: state.users.user,
  facility: state.centers.getCenterInfoSuccess,
  facilityLoading: state.centers.getCenterInfoLoading,
  drupalCoreData: state.publicContent?.getDrupalNavigationSuccess?.contents
})

const mapDispatchToProps = (dispatch) => ({
  getMessages: () => dispatch(PublicContentActions.getMessages())
})

export default connect(mapStateToProps, mapDispatchToProps)(Announcements)
