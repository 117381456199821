import React from 'react'
import { Segment, Image, Icon, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import { ReactComponent as HamburgerIcon } from '../../../../assets/media/icons/Hamburger-UI.svg'
import './styles.scss'
const { getMediaFromDrupalMimeType } = importer('Constant/Utils', null)
import { useTranslation } from 'react-i18next'

const MobileWebNavbar = ({ handleToggleSidebar, drupalNavData }) => {
  const mainNavBar = drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'main')
  const { t } = useTranslation('NavBar')

  return (
    <Segment.Group piled>
      <Segment className='SecondaryMobileWebNavbar'></Segment>
      <Segment className='PrimaryMobileWebNavbar'>
        <Segment.Group horizontal>
          <Segment style={{ height: 68 }}>
            <Link to='/'>
              {getMediaFromDrupalMimeType(mainNavBar?.field_logo?.mimetype, mainNavBar?.field_logo?.url, mainNavBar?.field_logo?.alt, {
                width: 96,
                height: 50
              })}
            </Link>
          </Segment>
          <div style={{ marginRight: '30px' }}>
            {mainNavBar ? (
              <PrimaryButton
                text={mainNavBar?.field_cta_1?.title}
                sendTo={mainNavBar?.field_cta_1?.uri}
                variant={mainNavBar?.field_cta_1_style}
                style={{ lineHeight: 0, padding: 15, fontSize: 14 }}
              />
            ) : null}
          </div>
          <Button onClick={handleToggleSidebar} className='HamburgerButton HamburgerIcon' aria-label={t('sideNavMenu')}>
            <HamburgerIcon />
          </Button>
        </Segment.Group>
      </Segment>
    </Segment.Group>
  )
}

const mapStateToProps = (state) => ({
  drupalHeaderMenu: state.publicContent.drupalMenuSuccess,
  drupalNavData: state.publicContent?.getDrupalNavigationSuccess?.contents
})

export default connect(mapStateToProps, null)(MobileWebNavbar)
