import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { setTextColor } from '../../../src/Constant/Utils'
import Accordion from '../../Components/Accordion'
import WirecardActions from '@takedapdt/biolife-core/src/Stores/Wirecard/Actions'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { isEmpty } from 'lodash'
import CustomModal from '../../Components/CustomModal'
import PrimaryButton from '../../Components/Buttons/PrimaryButton'
import { Icon, Message } from 'semantic-ui-react'
import { devices } from '../../utils/styledComponentHelpers'
import { ReactComponent as FilterIcon } from '../../../assets/media/icons/UI-filter-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/media/icons/closeIcon.svg'
import LoaderSpinner from '../Loaders/LoaderSpinner'
import { useScreenSize } from '../../Contexts/ResponsiveContextProvider/index'

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  padding: 2.4rem 0 0 0;
  border-top: 0.5px solid #ccc;
`

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`
const ItemActivityDate = styled.strong`
  font-size: 1.6rem;
  padding-top: 0.8rem;
  padding-bottom: 2.4rem;
`

const ItemPoints = styled.strong`
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 1.6rem;
`

const ItemDescription = styled.div`
  font-size: 1.6rem;
  padding: 0.6rem 0 2.4rem 0;
`
const TransactionEntries = styled.div``
const Text = styled.span`
  font-size: var(--caption-mobile-font-size) !important;
  font-family: var(--font-family-bold) !important;
  color: var(--color-dark-grey);
`
const TextContainer = styled.div`
  text-align: left;
  padding: 1.6rem 0;
  margin-top: 3rem;
  ${devices.mobile} {
    margin-top: 0;
  }
`
const DateFormat = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const StartDate = styled.div`
  height: 8.1rem;
`
const FilterTitle = styled.div`
  text-align: center;
  padding-bottom: 2rem;
`
const ModalData = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  column-gap: 1.6rem;
  ${devices.mobile} {
    display: contents;
  }
`
const EndDate = styled.div`
  height: 8.1rem;
`
const Filter = styled.span`
  background-color: var(--color-medium-shade-gray);
  padding: 0.4rem 0.8rem;
  border-radius: 0.4rem;
  font-size: var(--caption-mobile-font-size) !important;
  font-family: 'AvenirNextRegular' !important;
`
const DatePickerWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 34rem;
  margin-top: 0.6rem;
  width: 100%;
  ${devices.mobile} {
    width: 100%;
    min-width: 24rem;
  }
  .input {
    border-width: 0.1rem !important;
    border: 0.1rem solid red !important;
  }
`
const CalendarIcon = styled(Icon)`
  color: var(--color-light-orange);
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 1.6rem !important;
  height: 1.6rem !important;
`
const CustomDatePicker = styled(DatePicker)`
  min-width: 34rem;
  width: 100%;
  height: 5.6rem;
  ${devices.mobile} {
    width: 100%;
  }
`
const Popupcontainer = styled.div`
  border-bottom: 0.1rem solid var(--color-medium-shade-gray);
  margin-bottom: 4rem;
`
const DebitTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const FilterPopupContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  svg {
    width: 2.4rem !important;
    height: 2.1rem !important;
  }
  ${devices.mobile} {
    justify-content: flex-end;
    padding-bottom: 2.5rem;
  }
`
const FilterPopup = styled.p`
  font-size: var(--caption-font-size) !important;
  font-family: 'AvenirNextRegular' !important;
  color: var(--color-bl-blue);
`
const SelectedDateContainer = styled.div`
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FilterClose = styled.div`
  display: flex;
  background-color: var(--color-light-grey);
  border-radius: 1.9rem;
  padding: 0.8rem 1.6rem;
`
const SelectedDate = styled.p`
  font-size: var(--caption-mobile-font-size) !important;
  font-family: var(--font-family-bold) !important;
`
const CloseIconContainer = styled.div`
  padding-right: 0.8rem;
  padding-top: 0.1rem;
  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
`
const ClearFilter = styled.span`
  padding-left: 1.6rem;
  font-size: var(--caption-font-size) !important;
  font-family: 'AvenirNextRegular' !important;
  color: var(--color-bl-blue);
  ${devices.mobile} {
    padding-top: 2.4rem;
  }
`
const ClearFilterMobile = styled.span`
  font-size: var(--caption-font-size) !important;
  font-family: 'AvenirNextRegular' !important;
  color: var(--color-bl-blue);
  text-decoration: underline;
  padding-top: 3.2rem;
`
const WarningMessage = styled.div`
  margin-bottom: 4rem;
`
const WarningPopUpMessage = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 4rem;
`
const DebitHistoryPage = (props) => {
  const { donor, field_component_title, field_title_color, getDebitCardHistory, debitCardHistory, debitCardLoading } = props
  const [debitHistoryData, setDebitHistoryData] = useState()
  const { t, i18n } = useTranslation('DebitHistory')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterOn, setFilterOn] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { isMobileWidth } = useScreenSize()
  const getFilteredDates = sessionStorage.getItem('filterOnDebit')

  useEffect(() => {
    if (donor?.pdn) {
      // storing the debit filter details in session storage
      // on language translation the filter is getting removed
      //  using the dates in sessionStorage to make the api call on language translations
      window.addEventListener('beforeunload', function(e) {
        if (getFilteredDates) {
          window.sessionStorage.removeItem('filterOnDebit')
        }
      })
      if (getFilteredDates) {
        let filterDate = JSON.parse(sessionStorage.filterOnDebit)
        getDebitCardHistory(
          donor?.pdn,
          moment(filterDate?.startDate).format('YYYY-MM-DD'),
          moment(filterDate?.endDate).format('YYYY-MM-DD')
        )
      } else {
        getDebitCardHistory(donor?.pdn, null, null)
      }
    }
  }, [donor])

  useEffect(() => {
    if (getFilteredDates && !filterOn) {
      let filterDate = JSON.parse(sessionStorage.filterOnDebit)
      setStartDate(filterDate.startDate)
      setEndDate(filterDate.endDate)
    }
  }, [getFilteredDates])
  useEffect(() => {
    const transformeddebitHistoryData = new Map()
    debitCardHistory &&
      debitCardHistory?.data?.journal?.map((item) => {
        const date = moment(item.transactionDate).format('MMMM YYYY')
        if (transformeddebitHistoryData.has(date)) {
          transformeddebitHistoryData.get(date).push({
            ...item
          })
        } else {
          transformeddebitHistoryData.set(date, [
            {
              ...item
            }
          ])
        }
      })
    setDebitHistoryData(transformeddebitHistoryData)
  }, [debitCardHistory, i18n.language])

  /*This function updates the startDate*/
  const handleStartDateChange = (date) => {
    let dateDetails = { startDate: date, endDate: endDate }
    sessionStorage.setItem('filterOnDebit', JSON.stringify(dateDetails))
    setStartDate(date)
    setErrorMessage(null)
  }
  /* If startDate exists, date is provided, and date is after or equal to startDate:
Updates endDate with the selected date and clears any error message.
If date is before startDate:
*/

  const handleEndDateChange = (date) => {
    let dateDetails = { startDate: startDate, endDate: date }
    sessionStorage.setItem('filterOnDebit', JSON.stringify(dateDetails))
    setEndDate(date)
    setErrorMessage(null)
  }
  /*This function resets the filter state*/
  const clearFilter = () => {
    setFilterOn(false)
    setStartDate(null)
    setEndDate(null)
    setErrorMessage(null)
    sessionStorage.removeItem('filterOnDebit')
    getDebitCardHistory(donor?.pdn, null, null)
  }
  const clearFilterMobile = () => {
    setStartDate(null)
    setEndDate(null)
    setErrorMessage(null)
    sessionStorage.removeItem('filterOnDebit')
  }
  const handleOpen = () => {
    setFilterOn(true)
    setStartDate(null)
    setEndDate(null)
    sessionStorage.removeItem('filterOnDebit')
  }
  /*This function handles form submission for filtering:
Sets filterOn to false to close the modal after submission.
Checks if both startDate and endDate are selected and the difference between them is less than or equal to one year using Moment.js.
If valid dates are selected:
Formats the dates using Moment.js and calls getDebitCardHistory to fetch transactions within the specified date range.
*/
  const onSubmit = () => {
    if (startDate && endDate && endDate >= startDate) {
      setFilterOn(false)
      if (startDate && endDate && moment(endDate).diff(moment(startDate), 'year', true) <= 1) {
        getDebitCardHistory(donor?.pdn, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
      }
    } else if (endDate < startDate) {
      setErrorMessage(t('DateRange'))
    } else {
      setErrorMessage(t('invalidDateRange'))
      setEndDate(null)
    }
  }

  return (
    <>
      <div className='main-container debit-history-container'>
        <DebitTitle>
          <span
            style={{ ...setTextColor(field_title_color), padding: '2rem 3rem 2.4rem 0' }}
            dangerouslySetInnerHTML={{ __html: field_component_title?.value }}
          />
          {!isMobileWidth && (
            <FilterPopupContainer>
              <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
              <FilterIcon onClick={handleOpen} />
            </FilterPopupContainer>
          )}
        </DebitTitle>

        {debitCardLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            {isEmpty(debitCardHistory?.data?.journal) ? (
              <>
                <SelectedDateContainer>
                  <FilterClose>
                    <CloseIconContainer>
                      <CloseIcon onClick={clearFilter} />
                    </CloseIconContainer>
                    <SelectedDate>
                      {moment(startDate).format('MMMM DD, YYYY')} - {moment(endDate).format('MMMM DD, YYYY')}
                    </SelectedDate>
                  </FilterClose>
                  <ClearFilter onClick={clearFilter}>{t('clearFilter')}</ClearFilter>
                </SelectedDateContainer>
                {isMobileWidth && (
                  <FilterPopupContainer>
                    <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
                    <FilterIcon onClick={handleOpen} />
                  </FilterPopupContainer>
                )}
                <WarningPopUpMessage>
                  <Message negative>
                    <p>{t('noMatchingTransactionsFound')}</p>
                  </Message>
                </WarningPopUpMessage>
              </>
            ) : (
              <>
                {(!isEmpty(debitCardHistory?.data?.journal) && startDate && endDate) ||
                moment(endDate).diff(moment(startDate), 'year', true) <= 1 ? (
                  <>
                    <SelectedDateContainer>
                      <FilterClose>
                        <CloseIconContainer>
                          <CloseIcon onClick={clearFilter} />
                        </CloseIconContainer>
                        <SelectedDate>
                          {moment(startDate).format('MMMM DD, YYYY')} - {moment(endDate).format('MMMM DD, YYYY')}
                        </SelectedDate>
                      </FilterClose>
                      <ClearFilter onClick={clearFilter}>{t('clearFilter')}</ClearFilter>
                    </SelectedDateContainer>
                    {isMobileWidth && (
                      <FilterPopupContainer>
                        <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
                        <FilterIcon onClick={handleOpen} />
                      </FilterPopupContainer>
                    )}
                    <TransactionEntries>
                      {debitCardHistory?.data?.journal?.map((entry, index) => (
                        <ItemContainer key={index}>
                          <ItemActivityDate>{moment(entry.transactionDate).format('MMMM DD, YYYY')}</ItemActivityDate>
                          <ItemDetails>
                            <ItemPoints>
                              {entry.transactionAmount != null && entry.transactionAmount >= 0 ? '+' : '-'}$
                              {Math.abs(entry?.transactionAmount)}
                            </ItemPoints>
                            <ItemDescription>
                              {entry.transactionType.split(' ')[0] === 'Receive' ? t('received') : entry.transactionType}
                            </ItemDescription>
                          </ItemDetails>
                        </ItemContainer>
                      ))}
                    </TransactionEntries>
                  </>
                ) : (
                  <>
                    {debitHistoryData && !isEmpty(debitCardHistory?.data?.journal) && (
                      <>
                        {isMobileWidth && (
                          <FilterPopupContainer>
                            <FilterPopup onClick={handleOpen}>{t('filter')}</FilterPopup>
                            <FilterIcon onClick={handleOpen} />
                          </FilterPopupContainer>
                        )}
                        <>
                          {Array.from(debitHistoryData)?.map((item) => (
                            <Accordion
                              key={item[0]}
                              title={item[0]}
                              field_title_color={field_title_color}
                              description={<RenderList description={item[1]} />}
                            />
                          ))}
                        </>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <CustomModal openModal={filterOn} onClose={() => clearFilter()}>
        <DateFormat>
          <Popupcontainer>
            <FilterTitle>
              <Filter>{t('filterBy')}</Filter>
            </FilterTitle>
            <ModalData>
              <StartDate>
                <p className='selectfdatetext'>{t('startDate')}</p>
                <DatePickerWrapper>
                  <CustomDatePicker selected={startDate} onChange={handleStartDateChange} startDate={startDate} />
                  <CalendarIcon name='calendar alternate outline' size='large' />
                </DatePickerWrapper>
              </StartDate>
              <TextContainer>
                <Text>{t('to')}</Text>
              </TextContainer>
              <EndDate>
                <p className='selectfdatetext'>{t('endDate')}</p>
                <DatePickerWrapper>
                  <CustomDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    startDate={startDate}
                    selectsEnd
                    dayClassName={(date) => (startDate && date < startDate ? 'disabled' : null)}
                  />

                  <CalendarIcon name='calendar alternate outline' size='large' />
                </DatePickerWrapper>
              </EndDate>
            </ModalData>
          </Popupcontainer>
          {errorMessage && filterOn && (
            <WarningMessage>
              <Message negative>
                <p>{errorMessage}</p>
              </Message>
            </WarningMessage>
          )}
          <div>
            <PrimaryButton
              text={t('apply')}
              type='submit'
              className='apply'
              onClick={() => onSubmit()}
              disabled={!(startDate && endDate)}
            />
          </div>
          {isMobileWidth && <ClearFilterMobile onClick={clearFilterMobile}>{t('clearFilter')}</ClearFilterMobile>}
        </DateFormat>
      </CustomModal>
    </>
  )
}
const RenderList = ({ description }) => {
  const { t, i18n } = useTranslation('DebitHistory')
  return (
    <>
      {description?.map((item) => (
        <ItemContainer>
          <ItemActivityDate>{moment(item.transactionDate).format('MMMM DD, YYYY')}</ItemActivityDate>
          <ItemDetails>
            <ItemPoints>
              {item.transactionAmount != null && item.transactionAmount >= 0 ? '+' : '-'}${Math.abs(item.transactionAmount)}
            </ItemPoints>
            <ItemDescription>{item.transactionType === 'Receive $' ? t('received') : item.transactionType}</ItemDescription>
          </ItemDetails>
        </ItemContainer>
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  donor: state?.donors?.donors?.data,
  user: state.users.loginHerokuSuccess,
  debitCardHistory: state?.wirecard?.getWirecardInfoSuccess,
  debitCardLoading: state?.wirecard?.getWirecardInfoLoading,
  user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
  getDebitCardHistory: (pdn, startDate, endDate) => dispatch(WirecardActions.getWirecardInfo(pdn, startDate, endDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(DebitHistoryPage)
