import React from 'react'
import { Card, Segment } from 'semantic-ui-react'
import { ReactSVG } from 'react-svg'
import './HomeCardStyles.scss'
import styled from 'styled-components'
import LocationIcon from '../../../../assets/media/icons/icon-location-new.svg'
import PhoneIcon from '../../../../assets/media/icons/icon-phone-in-talk.svg'
import CheckIcon from '../../../../assets/media/icons/icon-done.svg'
import HomeCenterIcon from '../../../../assets/media/icons/icon-star-home-center.svg'
import FavCenterSelectedIcon from '../../../../assets/media/icons/location-heart-icon.svg'
import FavCenterIcon from '../../../../assets/media/icons/location-heart-fav-icon.svg'
import { useTranslation } from 'react-i18next'
import { formatPhoneNumber } from '../../../Helpers/Utils'
const favoriteCenter = 'Favorite-Center'
const homeCenter = 'Home-Center'
const HomeDescription = styled.div`
  height: 150px;
`

const ChangeHomeCenterCard = ({ isSelected, centerData, onCenterCardClick, isHomeCenter, changeCheck, isFavorite }) => {
  const { t } = useTranslation('AppointmentDetails')

  return (
    <Card
      raised
      onClick={() => onCenterCardClick()}
      className={isSelected ? 'HomeCenterCardContainer HomeCenterCardSelected' : 'HomeCenterCardContainer'}
    >
      <Card.Content>
        <Card.Header>
          <div className={`HeaderWrapper ${centerData?.distanceFromGivenZipcode ? '' : 'HideDistanceBox'}`}>
            {centerData?.distanceFromGivenZipcode ? (
              <div className='DistanceBox'>
                <ReactSVG className='LocationIconSmall' src={LocationIcon} />
                <span className='CenterDistance'>{`${centerData?.distanceFromGivenZipcode} mi`}</span>
              </div>
            ) : null}
            <div className='CheckMarkContainer'>
              <ReactSVG className='CheckMarkIcon' src={CheckIcon} />
            </div>
          </div>
        </Card.Header>
        <Card.Description className='HomeDescription'>
          <Segment basic>
            <div>
              <div className='CenterHeading'>
                <p className='CenterName'>{centerData?.name}</p>
                {isHomeCenter && !isFavorite && <ReactSVG className='HomeCenterIcon' src={HomeCenterIcon} />}
                {(isFavorite || (isFavorite && homeCenter)) && <ReactSVG className='FavCenterSelectedIcon' src={FavCenterSelectedIcon} />}
              </div>
              <div className='AddressBox'>
                <ReactSVG className='LocationIcon' src={LocationIcon} />
                <p className='CenterAddress'>
                  {centerData?.addressLine1 || centerData?.address}
                  <br />
                  {`${centerData?.city}, ${centerData?.stateCode} ${centerData?.zipcode || centerData?.zipCode}`}
                  <br />
                </p>
              </div>
              <div className='PhoneNumberBox'>
                <ReactSVG className='PhoneIcon' src={PhoneIcon} />
                <p className='PhoneNumber' href={`tel:${centerData?.telephone}`}>
                  {formatPhoneNumber(centerData?.telephone)}
                </p>
              </div>
              {centerData?.isSpanishSupported ? <p className='IsSpanishCenter'>{t('habloEspanol')}</p> : null}
            </div>
          </Segment>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default ChangeHomeCenterCard
