import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
import { ReactComponent as ButtonVideoPlayGrey } from '../../../../assets/media/icons/button-video-play-grey.svg'

export const TitleTextComponent = styled.p`
    ${() => setTextColor('orange')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 228px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    margin: 0rem 0rem 1.6rem 0rem;
    font-family: var(--font-family-bold) !important;
    `

export const TextComponent = styled.p`
  ${({ color }) => setTextColor(color)};
  padding-right: ${({ paddingRight }) => paddingRight};
  border-right: ${({ borderRight }) => borderRight};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily} !important` : 'AvenirNextBold !important')};
  margin: ${({ margin }) => margin || '0'};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  word-break: ${({ wordBreak }) => wordBreak};
  height: ${({ height }) => height || 'auto'};
  font-size: ${({ fontSize }) => `${fontSize} !important`};
  line-height: ${({ lineHeight }) => lineHeight || 'normal'};
`

export const Highlight4 = styled.h4`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
`

export const Highlight5 = styled.h5`
  ${({ color }) => setTextColor(color)};
  font-family: 'AvenirNextBold' !important;
  margin: ${({ margin }) => margin || '0'};
`

export const StyledDiv = styled.div`
  font-size: ${({ fontSize }) => `${fontSize} !important`};
  ${({ color }) => setTextColor(color)};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily} !important` : 'AvenirNextBold !important')};
  margin: ${({ margin }) => margin || '0'};
  height: ${({ height }) => height || 'auto'};
`

export const Container = styled.div`
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  margin-right: ${({ marginRight }) => marginRight};
  position: ${({ position }) => position};
`

export const ContainerWithFlex = styled.div`
  display: flex;
  background: ${({ bgColor }) => getCssColorFromDrupalColorType(bgColor)};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  overflow: ${({ overflow }) => overflow};
  /* width: ${({ width }) => width}; */
  position: ${({ position }) => position};
  box-shadow: ${({ boxShadow }) => boxShadow};
  height: ${({ height }) => height};
  flex-grow: ${({ flexGrow }) => flexGrow};
  margin-top: ${({ marginTop }) => marginTop};
  cursor: ${({ cursor }) => cursor};
`
export const ImageDiv = styled.div`
  height: 16rem;
  object-fit: cover;
  position: relative;
`
export const PlayIcon = styled(ButtonVideoPlayGrey)`
  width: 5rem;
  height: 5rem;
  border: 0;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`
export const Image = styled.img`
  object-fit: cover;
  object-position: center top;
  height: 100%;
  width: 100%;
`
