import React from 'react'
import { Card, Grid, Segment, Radio, Icon, Image } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { ReactSVG } from 'react-svg'
import './styles.scss'
import LocationIcon from '../../../assets/media/icons/icon-location-new.svg'
import PhoneIcon from '../../../assets/media/icons/icon-phone-in-talk.svg'
import { formatPhoneNumber } from '../../Helpers/Utils'
import { useTranslation } from 'react-i18next'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../NavigationService'

const CenterGetDirection = styled.div`
  .BlueUnderlineButton {
    color: var(--color-white) !important;
    font-size: var(--caption-font-size) !important;
  }
`

const ListViewCenterCard = ({ centerData, iconColor, key }) => {
  const { t } = useTranslation('AppointmentDetails')
  const openMap = (centerData) => {
    return window.open(`http://www.google.com/maps/place/${centerData.latitude},${centerData.longitude}`)
  }
  const navigate = useNavigate()
  const handleCenterClick = (centerData) => {
    navigate(`${ROUTES.Locations}/${centerData.state
                  .toLowerCase()
                  .split(' ')
                  .join('-')}/${centerData.centerUrlName}`)}
  
  return (
    <Card raised className='ListViewCenterCardContainer' key={key}>
      <Card.Content>
        <Card.Header>
          <div className='HeaderWrapper'>
            {centerData ? (
              <CenterGetDirection>
                <PrimaryButton text={t('getDirections')} variant={'blue_underline'} onClick={() => openMap(centerData)} />
              </CenterGetDirection>
            ) : null}
            {centerData.distanceFromGivenZipcode ? (
              <div className='DistanceBox'>
                <ReactSVG className={iconColor === 'white' ? '' : 'LocationIconSmall'} src={LocationIcon} />
                <span className='CenterDistance'>{`${centerData.distanceFromGivenZipcode} mi`}</span>
              </div>
            ) : null}
          </div>
        </Card.Header>
        <Card.Description onClick={()=>handleCenterClick(centerData)}>
          <Image height='125' width='100%' src={centerData.webImageUrl} alt={centerData.name} />
          <Segment basic>
            <div>
              <p className='CenterName'>{centerData.name}</p>
              <div className='AddressBox'>
                <ReactSVG className='LocationIcon' src={LocationIcon} />
                <p className='CenterAddress'>
                  {centerData.addressLine1}
                  <br />
                  {`${centerData.city}, ${centerData.stateCode} ${centerData.zipcode}`}
                  <br />
                </p>
              </div>
              <div className='PhoneNumberBox'>
                <ReactSVG className='PhoneIcon' src={PhoneIcon} />
                <a className='PhoneNumber' href={`tel:${centerData.telephone}`}>
                  {formatPhoneNumber(centerData.telephone)}
                </a>
              </div>
              {centerData.isSpanishSupported ? <p className='IsSpanishCenter'>{t('habloEspanol')}</p> : null}
            </div>
          </Segment>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default ListViewCenterCard
