import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import importer from 'common/importer'
import { StyledCustomPageWrapper } from './styles.js'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const renderDrupalComponent = importer('Constant/renderDrupalComponent')
const PageNotFound = importer('Components/PageNotFound')
const PatientTestimonials = importer('Components/PatientTestimonials')
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')
const { getClassFromPathname } = importer('Helpers/Utils', null)
const { getArrayFromTextCTAGrid } = importer('Helpers/Utils', null)

const CustomPage = ({ customPageData, customPageDataLoading, getCustomPage }) => {
  const { pathname } = useLocation()
  const [finalData, setFinalData] = useState({})

  useEffect(() => {
    getCustomPage(pathname.substring(1)) // extract one level slug from url
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    setFinalData(customPageData)
    if (customPageData) {
      const dataWithTextCTAGrid = getArrayFromTextCTAGrid(customPageData)
      if (Object.keys(dataWithTextCTAGrid)?.length) {
        setFinalData(dataWithTextCTAGrid)
      }
    }
  }, [customPageData])
  return (
    <StyledCustomPageWrapper>
      <Helmet>
        <title>{finalData?.metadata?.meta_title}</title>
        <meta name='description' content={`${finalData?.metadata?.meta_description}`} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {customPageDataLoading || (customPageData && !finalData?.contents) ? (
        <LoaderSpinner />
      ) : finalData?.contents ? (
        /*className is dynamically generated based on the pathname.
          @example
          if pathname is '/donation-process/faqs' then classname will be 'donation-process-faqs'*/
        <div className={getClassFromPathname(pathname)}>
          {/* for getting breadcrumbToggle from pageMetadata  */}
          {finalData?.contents?.map((component, index) => {
            if (component.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: finalData?.metadata?.field_content_breadcrumb,
                breadcrumbMobileToggle: finalData?.metadata?.field_mobile_breadcrumb,
              }
              component = tempComp
            }

            return renderDrupalComponent(component, index)
          })}
        </div>
      ) : (
        <PageNotFound />
      )}
    </StyledCustomPageWrapper>
  )
}

const mapStateToProps = (state) => ({
  customPageData: state.publicContent?.getCustomPageSuccess,
  // customPageMetaData: state.publicContent?.getCustomPageSuccess?.metadata,
  customPageDataLoading: state.publicContent?.getCustomPageLoading
})

const mapDispatchToProps = (dispatch) => ({
  getCustomPage: (slug) => dispatch(PublicContentActions.getCustomPage(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage)
