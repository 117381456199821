import React, { useEffect, useState } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import importer from 'common/importer'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { TabPane, Tab, Icon } from 'semantic-ui-react'
import GoogleMapReact from 'google-map-react'
const Config = importer('Config')
const ListViewCenterCard = importer('Components/ListViewCenterCard')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { getCssColorFromDrupalColorType, getCTAAlignment } = importer('Constant/Utils', null)

const CentersInAStateList = ({
  centers,
  field_cta_1: componentCTA,
  field_cta_1_style: componentCTAStyle,
  field_cta_alignment: componentCTAAlignment,
  field_bg_color: componentBackgroundColor,
  field_component_title: componentTitle,
  field_title_color: componentTitleColor,
  stateCenters
}) => {
  const { locationSlug } = useParams()
  const { t } = useTranslation('CentersInAStateList')
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const [centersListIndex, setCentersListIndex] = useState(isTabletWidth && !isMobileWidth ? 8 : 9)
  const search = JSON.parse(new URLSearchParams(location.search).get('search'))

  const sortedBydistance = search
    ? centers?.sort((a, b) => a?.distanceFromGivenZipcode - b?.distanceFromGivenZipcode) // sorting the List view Card data by distance based on zip codes
    : stateCenters?.sort((a, b) => a?.name?.localeCompare(b.name)) // sorting the city in state page alphabetically

  const MapMarker = ({ text }) => <div className='map-marker'></div>

  const Map = ({ width, height, defaultProps, mapKey, sites }) => {
    const onMapLoaded = ({ map, maps }) => {
      const bounds = new maps.LatLngBounds()
      if (sites.length === 1) {
        map.setZoom(14)
        return false
      }
      sites.forEach((site) => {
        bounds.extend(new maps.LatLng(site.latitude, site.longitude))
      })
      map.fitBounds(bounds)
    }

    return (
      <div className='Map' style={{ height: height, width: width }}>
        <GoogleMapReact
          key={mapKey}
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={onMapLoaded}
        >
          {sites && sites.map((center) => <MapMarker key={center.id} lat={center.latitude} lng={center.longitude} />)}
        </GoogleMapReact>
      </div>
    )
  }

  const MapViewComponent = () => (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
        style={{ color: getCssColorFromDrupalColorType(componentTitleColor) }}
      />
      <TabPane attached={false}>
        {sortedBydistance?.length > 0 && (
          <Map
            width='100%'
            height='80vh'
            sites={sortedBydistance}
            mapKey={sortedBydistance[0].id}
            defaultProps={{
              center: {
                lat: sortedBydistance[0].latitude,
                lng: sortedBydistance[0].longitude
              },
              zoom: 13
            }}
          />
        )}
      </TabPane>
    </>
  )

  const ListViewComponent = () => {
    return (
      <>
        <div
          dangerouslySetInnerHTML={{ __html: componentTitle?.processed }}
          style={{ color: getCssColorFromDrupalColorType(componentTitleColor) }}
        />
        <TabPane attached={false}>
          <div className='CenterListViewContainer'>
            {sortedBydistance?.slice(0, centersListIndex)?.map((item) => (
              <ListViewCenterCard centerData={item} iconColor='white' />
            ))}
          </div>
          {centersListIndex !== sortedBydistance?.length && sortedBydistance?.length > 9 ? (
            <div className='show-more-button'>
              <PrimaryButton text={t('showMore')} variant='outline_black' onClick={() => setCentersListIndex(sortedBydistance?.length)} />
            </div>
          ) : null}
        </TabPane>
      </>
    )
  }

  const panes = [
    {
      menuItem: t('mapView'),
      render: () => <MapViewComponent />
    },
    {
      menuItem: t('listView'),
      render: () => <ListViewComponent />
    }
  ]

  return (
    <div className='CenterListContainer' style={{ background: getCssColorFromDrupalColorType(componentBackgroundColor) }}>
      {search ? (
        <h5 style={{ padding: '0 0 20px 0' }}>
          {sortedBydistance?.length} {t('locationFoundFor')} {locationSlug}
        </h5>
      ) : null}
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} className='CenterListViewTypeTabs' defaultActiveIndex={1} />
      {componentCTA?.title && componentCTA?.uri ? (
        <div style={{ textAlign: getCTAAlignment(componentCTAAlignment) }}>
          <PrimaryButton text={componentCTA?.title} sendTo={componentCTA?.uri} variant={componentCTAStyle} />
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  centers: state.centers?.getDonorCentersAllSuccess?.data,
  zipcodeSearchedCenters: state.centers?.getZipcodeSearchedCentersSuccess?.data,
  locationsPageData: state.publicContent?.getLocationsPageSuccess?.contents,
  stateCenters: state.centers?.getStateCentersSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CentersInAStateList)
