import React, { useState } from 'react'
import { Image, Dropdown } from 'semantic-ui-react'
import './styles.scss'
import infoIcon from '../../../../assets/media/icons/InfoIcon.svg'
import { useTranslation } from 'react-i18next'
const PrimarySelect = (props) => {
  const {
    options,
    placeholder,
    className,
    required,
    error,
    handleOnChange,
    name,
    value,
    key,
    defaultValue,
    disabled,
    groupSelect,
    width,
    minWidth,
    height
  } = props
  const { t, i18n } = useTranslation('ContactForm', 'ValidationErrorMessages')
  const selectClasses = error ? `${className} Error` : className
  const [selectedValue, setSelectedValue] = useState(null)
  const [updated, setUpdated] = useState(false)
  const handleSelection = (option) => {
    setSelectedValue(option.key)
    setUpdated(true)
    handleOnChange(option.value)
  }
  return (
    <div className='PrimarySelect'>
      {groupSelect ? (
        <Dropdown
          className={`Base ${selectClasses} ${value ? 'value-selected' : ''} ${!selectedValue ? 'no-value' : ''}`}
          icon='chevron down'
          fluid
          selection
          style={{ minWidth: minWidth ? minWidth: 'auto', width: width ? width : '470px', height: height ? height : '56px' }}
          trigger={!updated && placeholder ? placeholder : t(`${selectedValue}`)}
        >
          <Dropdown.Menu>
            <Dropdown.Divider />
            {options.map((item) => (
              <>
                {item.header && <Dropdown.Header style={{ textTransform: i18n.language === 'es-MX' && 'none' }} content={item.category} />}
                {item.header ? (
                  item.subcategory.map((option) => (
                    <Dropdown.Item
                      className={option.key === selectedValue ? 'active selected' : ''}
                      key={option.value}
                      {...option}
                      defaultValue={defaultValue}
                      value={value || undefined}
                      onClick={() => handleSelection(option)}
                    />
                  ))
                ) : (
                  <Dropdown.Item
                    className={item.key === selectedValue ? 'active selected' : ''}
                    key={item.value}
                    {...item}
                    defaultValue={defaultValue}
                    value={value || undefined}
                    onClick={() => handleSelection(item)}
                  />
                )}
                <Dropdown.Divider />
              </>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Dropdown
          fluid
          style={{ minWidth: minWidth ? minWidth: 'auto', width: width ? width : '470px', height: height ? height : '56px' }}
          selection
          className={`Base ${selectClasses} ${value ? 'value-selected' : ''}`}
          placeholder={placeholder ? placeholder : ``}
          options={options}
          icon='chevron down'
          key={key || undefined}
          onChange={(e, { value }) => handleOnChange(value)}
          name={name}
          aria-label={name}
          defaultValue={defaultValue}
          value={value || undefined}
          disabled={disabled}
        />
      )}
      {error && (
        <div className='InfoIcon'>
          <Image src={infoIcon} width='24' height='24' />
        </div>
      )}
    </div>
  )
}

PrimarySelect.defaultProps = {
  className: 'PrimarySelect'
}
export default PrimarySelect
