import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import renderDrupalComponent from '../../Constant/renderDrupalComponent'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import { SLUG_TYPES } from '@takedapdt/biolife-core/src/Sagas/Constants/drupalConstants'
import { getPathDetails } from '../../Helpers/Utils'
import PublicContentActions from '@takedapdt/biolife-core/src/Stores/PublicContent/Actions'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import LoaderSpinner from '../Loaders/LoaderSpinner'
import ChooseDonationCenterForm from '../../Components/Forms/ChooseDonationCenterForm'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import DashboardBanner from '../../Components/Dashboard/DashboardBanner'
import { devices } from '../../utils/styledComponentHelpers'

const ChooseDonationCenterMainContainer = styled.div`
  background: #f5f5f5 0% 0% no-repeat padding-box;
  .text-component-description {
    padding-top: 0rem !important;
  }

  .CenterCardContainer {
    ${devices.mobile} {
      width: 32rem !important;
      margin: auto;
    }
  }
`

const TopBanner = styled.div`
  padding-bottom: 7.7rem;
`
const ChooseDonationCenterContainer = ({
  PostLoginPageData,
  PostLoginPageMetaData,
  PostLoginPageDataLoading,
  getPostLoginPage,
  centers,
  donor,
  user,
  donorBySfid,
  getDonorCentersAll,
  getDonorCentersAllLoading,
  session,
  getDonors
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    getPostLoginPage(getPathDetails.slugType || SLUG_TYPES?.CHOOSEDONATIONCENETR)
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (donor?.pdn && donor?.defaultCenter) {
      navigate('/dashboard/donor-dashboard')
    }
  }, [donor])

  useEffect(() => {
    getDonors(user && user.id)
  }, [])

  return (
    <ChooseDonationCenterMainContainer>
      <TopBanner>
        <DashboardBanner profileName={donor?.firstName} />
      </TopBanner>
      <Helmet>
        <title>{PostLoginPageMetaData?.meta_title}</title>
        <meta name='description' content={PostLoginPageMetaData?.meta_description} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
      {PostLoginPageDataLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          {PostLoginPageData?.map((component, index) => {
            if (component?.type === 'breadcrumb') {
              let tempComp = {
                ...component,
                breadcrumbToggle: PostLoginPageMetaData?.field_content_breadcrumb,
                breadcrumbMobileToggle: PostLoginPageMetaData?.field_mobile_breadcrumb
              }
              component = tempComp
            }
            return renderDrupalComponent(component, index)
          })}
        </>
      )}
      <div>
        <ChooseDonationCenterForm
          PostLoginPageData={PostLoginPageData}
          centers={centers}
          donor={donor}
          session={session}
          donorBySfid={donorBySfid}
          getDonorCentersAll={getDonorCentersAll}
          getDonorCentersAllLoading={getDonorCentersAllLoading}
          user={user}
        />
      </div>
    </ChooseDonationCenterMainContainer>
  )
}

const mapStateToProps = (state) => ({
  PostLoginPageData: state.publicContent?.getPostLoginPageSuccess?.contents,
  PostLoginPageMetaData: state.publicContent?.getPostLoginPageSuccess?.metadata,
  PostLoginPageDataLoading: state.publicContent?.getPostLoginPageLoading,
  centers: state.centers?.donorCenters?.data,
  center: state?.centers?.getAllCentersSuccess,
  donor: state.donors.donors?.data,
  donorBySfid: state?.donors?.getDonorBySfidSuccess?.data,
  getDonorCentersAllLoading: state?.centers?.getAllCentersLoading,
  session: state?.users?.session,
  user: state?.users?.user
})

const mapDispatchToProps = (dispatch) => ({
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  getPostLoginPage: (slug) => dispatch(PublicContentActions.getPostLoginPage(slug)),
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance) =>
    dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDonationCenterContainer)
