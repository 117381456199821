import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { devices } from '../../../utils/styledComponentHelpers'
import ChangeHomeCenterCard from '../../PreferenceSettings/Cards/ChangeHomeCenterCard'
import PrimaryButton from './../../Buttons/PrimaryButton'
import { useScreenSize } from './../../../Contexts/ResponsiveContextProvider'
import centersActions from '@takedapdt/biolife-core/src/Stores/Centers/Actions'
import donorsActions from '@takedapdt/biolife-core/src/Stores/Donors/Actions'
import importer from '@takedapdt/biolife-core/src/importer'
import { Grid, Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
const SearchInputButton = importer('Components/Inputs/SearchInputButton')
import { debounce } from 'lodash'
import TagManager from 'react-gtm-module'
import LoaderSpinner from '../../Loaders/LoaderSpinner'
const favoriteCenter = 'Favorite-Center'
const homeCenter = 'Home-Center'

const StyledHomeCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  ${devices.mobile} {
    padding-top: 4rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`

const StyledHomeCenterHeader = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-light-grey);
  padding: 1rem;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  ${devices.mobile} {
    font-size: 1.5rem;
    padding: 0.5rem;
  }
`
const StyledForm = styled.div`
  display: flex !important ;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 6.4rem;
  ${devices.mobile} {
    max-height: 36rem;
    padding: 2rem 4rem;
  }
`
const StyledHomeCenterOptions = styled.div`
  display: flex !important ;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;
  height: 51.2rem;
  overflow-y: auto !important;
  padding: 2rem 7.4rem;
  padding-left: ${(props) => (props.addExtraPaddingOneCenterOption ? '2rem' : '')};
  padding-right: ${(props) => (props.addExtraPaddingOneCenterOption ? '46rem' : '')};
  margin-bottom: 6rem;
  ${devices.mobile} {
    height: 25rem;
    padding: 2rem 1rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const StyledHomeCenterOption = styled.div`
  flex: 1 1;
  display: flex;
  justify-content: center;
  &:last-child {
    max-width: 36rem;
    ${devices.tablet} {
      max-width: unset;
    }
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  padding: 4rem 0 5rem;
  justify-content: center;
  position: sticky;
  z-index: 6;
  background: var(--color-white);
  margin: 0 !important;
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  /* can add shadow if required to the sticky button container */
  /* box-shadow: 0rem -0.4rem 0.8rem var(--color-box-shadow-grey); */
  bottom: 0;
  width: 100%;
  ${devices.mobile} {
    padding: 2.4rem 1.5rem 4rem;
  }
`

const ChangeHomeCenterPopup = ({
  donor,
  centers,
  togglePOPUp,
  setselectedCenterSFId,
  setIsSpanishSupported,
  donorAllCentersLoading,
  getDonorCentersAll,
  changeCheck,
  donorAllCenters,
  updateFavoriteCenter,
  favoriteCenterList,
  getFavoriteCenterLoading,
  center
}) => {
  const { isMobileWidth } = useScreenSize()
  const { t, i18n } = useTranslation('UpdatePreferencesForm')
  const [selectedCenter, setSelectedCenter] = useState([])
  const [selectedHomeCenter, setSelectedHomeCenter] = useState([])
  const [searchError, setSearchError] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [searchedCenterList, setSearchCenterList] = useState([])
  const [areaCodeText, setAreaCodeText] = useState(donor?.zipCode ? donor?.zipCode : '')
  const restrictSearch = donor?.defaultCenter?.isDIS8Center ? 'DIS8' : false
  useEffect(() => {
    if (submitted) {
      setSearchCenterList(donorAllCenters)
    }
  }, [donorAllCenters, submitted])

  useEffect(() => {
    if (selectedCenter?.length === 0 && favoriteCenterList?.length > 0) {
      setSelectedCenter(favoriteCenterList)
      setSearchCenterList([])
    }
  }, favoriteCenterList)

  const maxSelection = changeCheck === favoriteCenter ? 3 : 1

  const handleCenterSelection = (center) => {
    if (isCenterSelected(center)) {
      setSelectedCenter(selectedCenter.filter((selected) => selected?.sfid !== center?.sfid))
    } else if (selectedCenter?.length < maxSelection && changeCheck === favoriteCenter) {
      setSelectedCenter([...selectedCenter, center])
    } else if (changeCheck === homeCenter) {
      setSelectedHomeCenter(center)
    }
  }
  const onSubmit = ({ search }) => {
    search = search?.trim()
    if (!search) {
      return null
    }
    setSubmitted(true)
  }

  const pullDefaultSwitchCenterList = (isDIS8) => {
    if (!donor?.zipCode) {
      getDonorCentersAll(null, isDIS8, 'pubDate')
    } else {
      getDonorCentersAll(donor?.zipCode, isDIS8, 'pubDate')
    }
  }
  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      const isDIS8 = restrictSearch === 'DIS8' ? true : null
      if (nextValue?.length === 0) {
        pullDefaultSwitchCenterList(isDIS8)
      } else if (nextValue?.length >= 2) {
        setSubmitted(true)
        getDonorCentersAll(nextValue, isDIS8)
      }
    }, 1000),
    []
  )
  const handleOnChange = (value) => {
    if (!value.trim()) {
      setSearchCenterList([])
      return
    }
    debouncedSearch(value)
  }

  const handleChangeCenter = () => {
    if (changeCheck === favoriteCenter) {
      const selectedCenterSFId = selectedCenter?.map((center) => {
        return center?.sfid
      })
      updateFavoriteCenter(donor?.sfid, selectedCenterSFId)
      setSelectedCenter(selectedCenterSFId)
      setIsSpanishSupported(selectedCenter[0]?.isSpanishSupported)
      TagManager.dataLayer({
        dataLayer: {
          event: 'favoriteCenterSelection',
          donorID: donor?.sfid
        }
      })
    } else if (changeCheck === homeCenter && selectedHomeCenter?.sfid) {
      setIsSpanishSupported(selectedHomeCenter?.isSpanishSupported)
      setSelectedHomeCenter(selectedHomeCenter?.centerNum)
      setselectedCenterSFId(selectedHomeCenter?.sfid)
    }
    togglePOPUp(false)
    setSearchCenterList([])
  }

  const isCenterSelected = (center) => {
    return changeCheck === favoriteCenter
      ? selectedCenter?.some((select) => select?.sfid === center?.sfid)
      : center?.sfid === selectedHomeCenter?.sfid
  }
  const displayCenterList = changeCheck === homeCenter ? centers : searchedCenterList
  return (
    <div>
      <StyledHomeCenterContainer>
        <StyledHomeCenterHeader>{changeCheck === homeCenter ? t('changeHomeCenter') : t('addFavLocation')}</StyledHomeCenterHeader>
        {changeCheck === favoriteCenter && (
          <StyledForm>
            <FinalForm
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className='fields icon-arrow'>
                    <Field
                      name='search'
                      render={({ input, meta }) => (
                        <SearchInputButton
                          fieldLabel={t('searchBy')}
                          type='text'
                          {...input}
                          defaultValue={''}
                          height={56}
                          width={isMobileWidth ? 230 : 650}
                          error={searchError}
                          errorMessage={`${t('errorMessage1')} <a id='search-input-error' href='/about-biolife/contact-us'>${t(
                            'contactUs'
                          )}</a> ${t('errorMessage2')}`}
                          onBlur={() => {
                            input.onBlur()
                          }}
                          onChange={(e, val) => {
                            searchError ? setSearchError(false) : null, handleOnChange(val.value), input.onChange(val.value)
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Form>
              )}
            />
          </StyledForm>
        )}

        <StyledHomeCenterOptions addExtraPaddingOneCenterOption={displayCenterList?.length > 1 ? false : true}>
          {donorAllCentersLoading || getFavoriteCenterLoading ? (
            <LoaderSpinner />
          ) : (
            displayCenterList?.map((center, index) => (
              <StyledHomeCenterOption key={index}>
                <ChangeHomeCenterCard
                  key={center?.id}
                  onCenterCardClick={() => {
                    handleCenterSelection(center)
                  }}
                  isSelected={isCenterSelected(center)}
                  centerData={center}
                  isHomeCenter={center?.sfid === donor?.defaultCenter?.sfid}
                  changeCheck={changeCheck}
                  isFavorite={favoriteCenterList?.find((item) => item?.centerNum === center?.centerNum)}
                />
              </StyledHomeCenterOption>
            ))
          )}
        </StyledHomeCenterOptions>
        <StyledButtonContainer id='fixedButton'>
          <PrimaryButton
            onClick={handleChangeCenter}
            variant={'solid_blue'}
            text={changeCheck === favoriteCenter ? t('selectLocation') : t('setAsHome')}
            fluid={isMobileWidth}
            disabled={changeCheck === favoriteCenter ? (selectedCenter.length ? false : true) : selectedHomeCenter?.zipcode ? false : true}
          />
        </StyledButtonContainer>
      </StyledHomeCenterContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors.donors?.data,
  drupalMetaData: state.publicContent?.getDrupalNavigationSuccess?.metadata,
  centers: state.centers.donorCenters?.data,
  center: state.centers.getAllCentersSuccess,
  donor: state.donors.donors?.data,
  nearbyCenters: state.centers.nearbyCenters,
  centerStatus: state.centers.centerStatus,
  donorAllCenters: state?.centers?.getDonorCentersAllSuccess?.data,
  donorAllCentersLoading: state?.centers?.getDonorCentersAllLoading,
  favoriteCenterList: state?.centers?.favouriteCenters,
  getFavoriteCenterLoading: state?.centers?.getFavoriteCenterInfoLoading
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr) => dispatch(centersActions.getDonorCentersAll(searchStr)),
  getCenterDetails: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  getDonorNearbyCenters: (donorSfid) => dispatch(centersActions.getDonorCenterInfo(donorSfid)),
  updateFavoriteCenter: (donorSfid, centerSfid) => dispatch(centersActions.updateFavoriteCenter(donorSfid, centerSfid)),
  getFavoriteCenters: (donorSfid) => dispatch(centersActions.getFavoriteCenterInfo(donorSfid))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeHomeCenterPopup)
