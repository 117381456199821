import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { numberWithCommas } from '../../../Helpers/Utils'
import importer from '@takedapdt/biolife-core/src/importer'
import { numberWithCommasWithOutDecimals } from '../../../Helpers/Utils'
import { ROUTES } from '../../../NavigationService'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoaderBar = importer('Components/Loaders/LoaderBar')
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
import {
  DashboardInfoContainer,
  Title,
  CardsContainer,
  Balance,
  BalanceTitle,
  BalanceContent,
  RewardPoints,
  RewardPointsTitle,
  RewardPointsContent,
  PhysicalDates,
  LastPhysicalDateWrapper,
  LastPhysicalDateTitle,
  LastPhysicalDate,
  NextPhysicalDateWrapper,
  NextPhysicalDateTitle,
  NextPhysicalDate
} from './styled'
const DashboardStatistics = (props) => {
  const wirecard = useSelector((state) => state.wirecard.getWirecardInfoSuccess?.data)
  const loyaltyPoints = useSelector((state) => state.loyalty.getLoyaltyDetails?.data?.loyaltyPoints)
  const loyaltyPointsLoading = useSelector((state) => state.loyalty.getLoyaltyDetailsLoading)
  const donorInquiry = useSelector((state) => state.donors.inquiryDonorSuccess?.data)
  const donorInquiryLoading = useSelector((state) => state.donors.inquiryDonorLoading)
  const { t, i18n } = useTranslation('donorDashboardPage')
  const { field_component_title: sectionTitle, field_title_color: sectionTitleColor, handleLoyaltyEnrolmentButtonClick, donor } = props
  const hasInquiryData = donorInquiry && (donorInquiry.lastPhysicalDate || donorInquiry.nextPhysicalDate)

  const debitCardBalanceComponent = (
    <Balance $hasInquiryData={hasInquiryData}>
      <BalanceTitle $hasInquiryData={hasInquiryData}>{t('balanceTitle')}</BalanceTitle>
      <BalanceContent $hasInquiryData={hasInquiryData} $hasNoBalance={isEmpty(wirecard)}>
        {!isEmpty(wirecard) ? (
          <span className='card_balance'>
            {wirecard?.balance?.balanceAvailable ? `$${numberWithCommas(wirecard?.balance?.balanceAvailable)}` : 0}
          </span>
        ) : (
          <PrimaryButton text={t('getPaidForDonating')} variant='orange_arrow_right' sendTo='internal:/appointment-scheduling' />
        )}
      </BalanceContent>
    </Balance>
  )

  const rewardPointsComponent = (
    <RewardPoints $hasInquiryData={hasInquiryData}>
      <RewardPointsTitle $hasInquiryData={hasInquiryData}>{t('rewardPointsTitle')}</RewardPointsTitle>
      <RewardPointsContent $isLoyaltyMember={donor?.isLoyaltyMember && donor?.isLoyaltyMember === 'Yes' && props.$loyaltyPoints}>
        {donor?.isLoyaltyMember && donor?.isLoyaltyMember === 'Yes' ? (
          <span className='loyalty_points'>{loyaltyPoints ? numberWithCommasWithOutDecimals(loyaltyPoints) : 
          <PrimaryButton text={t('donateToGetPoints')} variant='orange_arrow_right' sendTo='internal:/appointment-scheduling' />
          }</span>
        ) : (
          <PrimaryButton text={t('joinNowToEarnPoints')} variant='orange_arrow_right' onClick={() => handleLoyaltyEnrolmentButtonClick()} />
        )}
      </RewardPointsContent>
    </RewardPoints>
  )
  return (
    <DashboardInfoContainer>
      <div className='main-container'>
        <Title $sectionTitleColor={sectionTitleColor} dangerouslySetInnerHTML={sanitizeHtml(sectionTitle?.processed)} />
        {donorInquiryLoading || loyaltyPointsLoading ? (
          <div className='LoaderContainer'>
            <LoaderBar />
          </div>
        ) : (
          <CardsContainer $hasInquiryData={hasInquiryData}>
            {!isEmpty(wirecard) && wirecard?.balance?.balanceAvailable ? (
              <Link className='link-style' to={`/${ROUTES.Debit}`}>
                {debitCardBalanceComponent}
              </Link>
            ) : (
              debitCardBalanceComponent
            )}
            {donor?.isLoyaltyMember && donor?.isLoyaltyMember === 'Yes' && loyaltyPoints ? (
              <Link className='link-style' to={`/${ROUTES.MyRewards}`}>
                {rewardPointsComponent}
              </Link>
            ) : (
              rewardPointsComponent
            )}
            {hasInquiryData ? (
              <PhysicalDates $hasInquiryData={hasInquiryData}>
                <LastPhysicalDateWrapper>
                  <LastPhysicalDateTitle $language={i18n.language}>{t('lastPhysicalDueDashboard')}</LastPhysicalDateTitle>
                  <LastPhysicalDate>
                    {donorInquiry && donorInquiry.lastPhysicalDate ? moment(donorInquiry.lastPhysicalDate).format('MM/DD/YY') : null}
                  </LastPhysicalDate>
                </LastPhysicalDateWrapper>
                <NextPhysicalDateWrapper>
                  <NextPhysicalDateTitle $language={i18n.language}>{t('nextPhysicalDueDashboard')}</NextPhysicalDateTitle>
                  <NextPhysicalDate>
                    {donorInquiry && donorInquiry.nextPhysicalDate ? moment(donorInquiry.nextPhysicalDate).format('MM/DD/YY') : null}
                  </NextPhysicalDate>
                </NextPhysicalDateWrapper>
              </PhysicalDates>
            ) : null}
          </CardsContainer>
        )}
      </div>
    </DashboardInfoContainer>
  )
}
export default DashboardStatistics
