import styled from 'styled-components'
import { devices } from '../../../utils/styledComponentHelpers'
import { StyledHorizontalCalendar, StyledHorizontalCalendarWrapper } from '../HorizontalCalendar/styled'
import { StyledTimeSlotPickerWrapper } from '../TimeSlotPicker/styled'
import { StyledHeaderContainer } from '../AppointmentScheduleHeader'
import { LoaderBarContainer } from '../../Loaders/LoaderBar'

export const StyledSubmitAppointment = styled.div`
  text-align: center;
  z-index: 6;
  background: var(--color-white);
  margin: 0;
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  width: 100%;
  position: absolute;
  bottom: 0;

  ${devices.mobile} {
    box-shadow: 0rem -0.4rem 0.8rem var(--color-box-shadow-grey);
    gap: 3.2rem;
    position: absolute;
    bottom: 0;
  }
`
export const StyledButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding: 2.4rem 33rem 7.5rem;
  justify-content: center;
  ${devices.tablet} {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  ${devices.mobile} {
    padding: 2.4rem 1.6rem 4.8rem;
  }
`
export const StyledFailureState = styled.div`
  width: 66.4rem;
  margin: 1.6rem auto 0;
  ${devices.mobile} {
    width: 34.3rem;
  }
`
export const StyledAppointmentRechedulerModalHeader = styled.div`
  margin: 3.2rem auto 0;
  border-radius: 0.4rem;
  background: var(--color-medium-shade-gray);
  font-size: var(--caption-mobile-font-size);
  padding: 0.4rem 0.8rem;
  width: fit-content;

  ${devices.mobile} {
    margin: 6.2rem auto 0;
  }
`
export const StyledAppointmentRechedulerModal = styled.div`
  overflow-y: scroll;
  max-height: ${({ $isChangeLocationModal }) => ($isChangeLocationModal ? 0 : '90vh')};
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ${devices.mobile} {
    max-height: ${({ $isChangeLocationModal }) => ($isChangeLocationModal ? 0 : '100vh')};
  }

  ${StyledHeaderContainer} {
    padding-top: 3.5rem;
    font-size: large;
    ${devices.mobile} {
      padding-top: 1.6rem;
      h4 {
        padding-top: 0.75rem;
      }
    }
  }
  ${StyledHorizontalCalendar} {
    ${devices.mobile} {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }
  }
  ${StyledHorizontalCalendarWrapper} {
    width: 83.6rem;
    margin: 0 auto;
    padding-left: 1.4rem;
    padding-right: 1.4rem;

    ${devices.tablet} {
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 0;
      width: 100%;
    }
    ${devices.mobile} {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
  }
  ${StyledTimeSlotPickerWrapper} {
    padding-bottom: 25.7rem;
  }
  ${LoaderBarContainer} {
    position: relative;
  }
`
