import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { useNavigate } from 'react-router-dom'
import { getButtonClassNames } from '../buttonUtilityFunctions'
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import { ReactComponent as RightArrow } from '../../../../assets/media/icons/UI-arrow-right.svg'
import { ReactComponent as LeftArrow } from '../../../../assets/media/icons/UI-arrow-left.svg'
import './styles.scss'

const PrimaryButton = ({ text = 'Primary', disabled: disable = false, variant, sendTo: to = '', className = ``, style = {}, ...props }) => {
  if (typeof to !== 'string') {
    console.error('sendTo Prop In Button should be of string type')
  }
  if (to && !to.length) {
    console.error('sendTo prop is not having any value!')
  }
  let { basic = false, fluid = false, type, onClick, onClickParams = {}, as, href, loading, ...restParams } = props
  const defaultButtonStyling = {
    fontSize: '18px',
    padding: '16px 30px',
    width: !fluid || variant === 'blue_underline' ? 'auto' : '100%'
  }
  if (to && (to.includes('https') || to.includes('http'))) {
    restParams = {
      ...restParams,
      href: to,
      target: '_blank'
    }
  } else if (to && to?.includes('#')) {
    const urlSegment = to?.split('#')[1]
    restParams = {
      ...restParams,
      target: `internal:#${urlSegment}`
    }
  }
  const { isMobileWidth } = useScreenSize()
  const navigate = useNavigate()
  //Some button types like black_underline button do not take full width in mobile and tablet view
  // const finalFluidValue = isMobileWidth ? true : fluid
  const buttonStyles = getButtonClassNames(variant)
  const routeChange = (path) => {
    if (!path) {
      return
    }
    const origPath = (path && path.includes('internal:')) ? path.split(':')[1] : path
    navigate(origPath)
  }

  const handleClick = ({ to }) => {
    if (restParams['href']) {
      window.open(restParams['href'], restParams['target'] || '_blank')
      return
    }
    if (onClick && typeof onClick === 'function') {
      onClick({ ...onClickParams })
      return
    }
    routeChange(to)
  }
  return (
    <button
      style={
        style && Object.keys(style).length
          ? {
              ...style,
              ...(style.padding ? { padding: style.padding } : { padding: defaultButtonStyling.padding }),
              ...(style.fontSize ? { fontSize: style.fontSize } : { fontSize: defaultButtonStyling.fontSize }),
              ...(style.width ? { width: style.width } : { width: defaultButtonStyling.width })
            }
          : defaultButtonStyling
      }
      basic={basic}
      disabled={disable}
      className={`${(!basic && buttonStyles) || ``} ${className} ui button ${loading ? 'button-loading disabled' : ''}`} // need to remove Own styles if basic prop is there
      type={type}
      onClick={() => handleClick({ to })}
      aria-label={text ? text : 'primary button'}
      {...restParams}
    >
      {buttonStyles?.includes('ArrowLeft') && (
        <LeftArrow
          className={
            buttonStyles?.includes('ArrowLeft')
              ? window.navigator.userAgent.toLowerCase().indexOf('android') !== -1 ||
                window.navigator.userAgent.toLowerCase().indexOf('windows') !== -1
                ? 'leftArrow Windows'
                : 'leftArrow'
              : 'arrow'
          }
        />
      )}
      {text}
      {buttonStyles?.includes('ArrowRight') && (
        <RightArrow
          className={
            buttonStyles?.includes('ArrowRight')
              ? window.navigator.userAgent.toLowerCase().indexOf('android') !== -1 ||
                window.navigator.userAgent.toLowerCase().indexOf('windows') !== -1
                ? 'rightArrow Windows'
                : 'rightArrow'
              : 'arrow'
          }
        />
      )}
    </button>
  )
}

export default PrimaryButton
