import styled from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const { devices } = importer('utils/styledComponentHelpers', null)
import backgroundImage from '../../../../assets/media/images/dashboard_banner.svg'

export const DashboardBannerContainer = styled.div`
  background-color: var(--color-smoke-grey);
`

export const DashboardBannerWrapper = styled.div`
  //Do not remove the below commented styles
  /* background: linear-gradient(to bottom, var(--color-dark-orange),  var(--color-bl-orange)); */
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  max-width: 1366px;
  margin: 0 auto;
  ${devices.mobile} {
    border-bottom-left-radius: 64px;
    border-bottom-right-radius: 64px;
  }
`

export const DashboardBannerInnerWrapper = styled.div`
  display: flex;
  padding-top: 48px;
  padding-bottom: 48px;
  ${devices.mobile} {
    padding-top: 24px;
    padding-bottom: 48px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const DashboardBannerImageBlock = styled.div`
  margin-right: 24px;
  height: 55px;
  width: 55px;
  ${devices.mobile} {
    margin-right: 0;
    img {
      width: 40px;
    }
  }
`

export const DashboardBannerTextBlock = styled.div`
  color: var(--color-white);
  p:first-child {
    font-size: var(--caption-font-size) !important;
    font-family: var(--font-family-bold) !important;
    ${devices.mobile} {
      font-size: var(--caption-mobile-font-size) !important;
      font-family: var(--font-family-regular) !important;
    }
  }
  p:last-child {
    font-size: var(--h4-font-size) !important;
    font-family: var(--font-family-bold) !important;
    ${devices.mobile} {
      font-size: var(--h4-mobile-font-size) !important;
    }
  }
`
