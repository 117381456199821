import { put } from 'redux-saga/effects'
import importer from '@takedapdt/biolife-core/src/importer'
const PublicContentActions = importer('Stores/PublicContent/Actions')
const CentersActions = importer('Stores/Centers/Actions')
const { ROUTES } = importer('NavigationService', null)
const { getPathDetails } = importer('Helpers/Utils', null)
const { SLUG_TYPES } = importer('Sagas/Constants/drupalConstants')

/**
 * PUBLIC_STARTUP_SUCCESS
 */
function* initializeAppSuccess() {
  const { pageRoute, routeName, segments } = getPathDetails()
  /**
   * GET APP IMAGES
   */
  // Get Data from Drupal for the common components
  yield put(PublicContentActions.getDrupalNavigation())
  yield put(PublicContentActions.getPromotionalBanner(SLUG_TYPES.PROMOTIONALBANNER))
  /**
   * Get Data from Drupal for the Route specific components when App is initialized
   * When translation happens this will load only data for that specific route
   * */
  switch (pageRoute) {
    case ROUTES.Welcome:
      yield put(PublicContentActions.getDrupalHomepage())
      break
    case ROUTES.NoCenterZone:
      yield put(PublicContentActions.getRegistrationPage(routeName))
      break
    case ROUTES.EmailConfirmation:
      yield put(PublicContentActions.getRegistrationPage(routeName))
      break
    case ROUTES.SignIn:
      yield put(PublicContentActions.getFormsPage(routeName))
      break
    case ROUTES.CheckEmail:
      yield put(PublicContentActions.getFormsPage(routeName))
      break
    case ROUTES.AccountSetupSuccess:
      yield put(PublicContentActions.getFormsPage(routeName))
      break
    case ROUTES.AuthForgotPassword:
      yield put(PublicContentActions.getFormsPage(routeName))
      break
    case ROUTES.SocialLogin:
      yield put(PublicContentActions.getFormsPage(routeName))
      break
    case ROUTES.ShareWithUs:
      yield put(PublicContentActions.getFormsPage(routeName))
      break
    case ROUTES.AuthSignUp:
      yield put(PublicContentActions.getRegistrationPage(routeName))
      break
    case ROUTES.VerifyEmail:
      yield put(PublicContentActions.getRegistrationPage(routeName))
      break
    case ROUTES.CreateAccount:
      yield put(PublicContentActions.getRegistrationPage(routeName))
      break
    case ROUTES.AuthResetPassword:
      yield put(PublicContentActions.getFormsPage(routeName))
      break
    case ROUTES.VerifyAccountFromEmail:
      yield put(PublicContentActions.getFormsPage(SLUG_TYPES.RESETPASSWORD))
      break
    case ROUTES.Locations:
      if (segments.length > 2 && segments[2].length > 0) {
        if (isNaN(segments[2])) {
          if (segments[3] && segments[3]?.length > 0) {
            yield put(CentersActions.getCenterInfo(segments[3], segments[2]))
          } else {
            const locationType = window.localStorage.getItem('locationType')
            if (locationType === 'locationNotFound') yield put(PublicContentActions.getLocationsPage('locations/location-not-found'))
            else if (locationType === 'stateLocation') yield put(PublicContentActions.getLocationsPage(`${routeName}/${segments[2]}`))
            else yield put(PublicContentActions.getLocationsPage(`${routeName}/state`))
          }
        } else {
          yield put(PublicContentActions.getLocationsPage(`${routeName}/state`))
        }
      } else {
        yield put(PublicContentActions.getLocationsPage(`${routeName}`))
      }
      break
    case ROUTES.Dashboard:
      let newDonorDashboardRouteName = routeName
      if (segments.length > 3 && segments[3].length > 1) {
        newDonorDashboardRouteName = segments.slice(2).join('/')
      } else {
        newDonorDashboardRouteName = `${segments.slice(1).join('/')}`
      }
      yield put(PublicContentActions.getLoyaltyAchievementBadges())
      break
    case ROUTES.Preview:
      break
    default:
      /**
       * This is to check if the segments array has more than two values.
       * Append that segment value to updated route with '/' for all the iterations.
       * */
      let updatedRouteName = routeName
      segments.map((segment, index) => {
        if (index > 1) updatedRouteName = `${updatedRouteName}/${segment}`
      })

      /** Check if updated route name contacts 'contact-us' as this page uses getFormsPage method instead of getCustomPage. */
      if (updatedRouteName.includes(ROUTES.ContactUs)) {
        yield put(PublicContentActions.getFormsPage(updatedRouteName))
        return
      }
      yield put(PublicContentActions.getCustomPage(updatedRouteName))
      break
  }
}

export default initializeAppSuccess
