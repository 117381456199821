
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Grid, Menu } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const CommonView = importer('Components/Footer/FooterViews/CommonView')
const { COMMONVIEW_TYPES } = importer('Constant/commonviewComponentTypes', null)
const { ScrollToTop } = importer('Helpers/Utils', null)
import { CareersSpanishURL } from '../../../NavigationService'

const MoblieFooter = ({footerMenuData, footerContentData}) => {
    // Number of items per menu group
    const itemsPerGroup = 4;
    // Calculate the number of menu groups
    const numGroups = footerMenuData ? Math.ceil(footerMenuData?.length / itemsPerGroup) : [];
    const logoAlignment = footerContentData?.biolifeLogo.alignment;
    return (
        <Grid className='MobileFooterContainer' verticalAlign='bottom' stackable>
            <Grid.Row columns={3} className={`FooterTopRow ${ logoAlignment === 2 ? 'Reversed' : ''}`}>
                <Grid.Column mobile={16} className='BiolifeLogoBox'>
                    <CommonView sectionType={COMMONVIEW_TYPES.BIOLIFE_LOGO_BLOCK} contentData={footerContentData}/>
                </Grid.Column>
                {/* <Grid.Column mobile={16} className='SubscriptionBox'> */}
                    {/* Input box for user subscription to be implemented in future sprints*/}
                    {/* <CommonView sectionType={COMMONVIEW_TYPES.SUBSCRIPTION_BOX_BLOCK} contentData={footerContentData}/> */}
                {/* </Grid.Column> */}
                <Grid.Column className='StoreLogoContainer' mobile={16}>
                    <CommonView sectionType={COMMONVIEW_TYPES.APP_STORE_LOGOS_BLOCK} contentData={footerContentData}/>
                </Grid.Column>
            </Grid.Row>
            <hr style={{width: '100%', marginTop: '20px', marginLeft: '14px', marginRight: '14px'}}/>
            <Grid.Row columns={1} className='FooterMiddleRow'>
                <Grid.Column mobile={16}>
                <Menu secondary widths={2} className='FooterMenu'>
                    {/* Iterate over numGroups and create a Menu for each group */}
                    {[...Array(numGroups)].map((_, groupIndex) => (
                    <Menu.Item className='FooterMiddleRowMenuGroup' key={groupIndex}>
                        <Menu position='left' vertical={true} secondary>
                        {/* Slice the menu items for the current group */}
                        {footerMenuData?.slice(groupIndex * itemsPerGroup, (groupIndex + 1) * itemsPerGroup).map((item, itemIndex) => (
                            item?.href.includes('https') ? (
                                <Menu.Item 
                                    className={item?.class} 
                                    key={itemIndex} 
                                    name={item?.title}
                                    content={item?.title} 
                                    as='a' 
                                    href={(item?.title?.toLowerCase() === 'carreras' ? CareersSpanishURL : item?.href)}
                                    target="_blank"
                                    aria-label={item?.title}
                                />
                                ) : (
                                <Menu.Item 
                                    className={item?.class} 
                                    key={itemIndex} 
                                    name={item?.title}
                                    content={item?.title}
                                    onClick={ScrollToTop} 
                                    as={NavLink} 
                                    to={item?.href} 
                                />
                            )
                        ))}
                        </Menu>
                    </Menu.Item>
                    ))}
                </Menu>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='FooterBottomRow'>
            <Grid.Column mobile={16} textAlign='center' className='TakedaLogo'>
            {footerContentData?.field_takeda_logo ? 
                        <CommonView sectionType={COMMONVIEW_TYPES.TAKEDA_LOGO_BLOCK} contentData={footerContentData}/>
                    : null}
                </Grid.Column>
                <Grid.Column mobile={16} textAlign='center' className='SocialIcons'>
                    <CommonView sectionType={COMMONVIEW_TYPES.SOCIAL_MEDIA_ICONS_BLOCK} contentData={footerContentData}/>
                </Grid.Column>
                <Grid.Column mobile={16} className='TrademarkStatement'>
                    {footerContentData?.biolifeTrademarkStatement?.text ? 
                        <CommonView sectionType={COMMONVIEW_TYPES.TRADEMARK_STATEMENT_BLOCK} contentData={footerContentData}/>
                    : null}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
export default MoblieFooter;