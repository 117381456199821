import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import importer from '@takedapdt/biolife-core/src/importer'
const Config = importer('Config')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { devices, sizes } = importer('utils/styledComponentHelpers', null)
const { sanitizeHtml } = importer('utils/htmlHelpers', null)
const { parseExtVideoLink } = importer('Helpers/Utils', null)

const flexDirection = {
  1: `row-reverse`,
  2: `row`,
  3: `column-reverse`,
  4: `column`
}

const textAlign = {
  1: `left`,
  2: `left`,
  3: `center`,
  4: `center`
}

const mediaStyles = {
  1: css`
    flex: 1;
  `,
  2: css`
    flex: 1;
  `
}

const StyledTextMediaTemplate3Background = styled.div`
  background: ${(props) => getCssColorFromDrupalColorType(props.$bgColor)};
`

const StyledTextMediaTemplate3 = styled.div`
  display: flex;
  flex-direction: ${(props) => flexDirection[props.$mediaAlignment]};
  gap: 4.8rem;
  align-items: center;
  max-width: 100.8rem;
  margin: auto;
  ${devices.tablet} {
    padding: 0 var(--padding-horizontal-tablet);
  }
  ${devices.mobile} {
    flex-direction: column;
    padding: 0 var(--padding-horizontal-mobile);
  }
`

const StyledTextMediaTemplate3TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  ${(props) => mediaStyles[props.$mediaAlignment]}
`

const StyledTextMediaTemplate3Text = styled.div`
  ${(props) => setTextColor(props.$textColor)};
  text-align: ${(props) => textAlign[props.$flexDirection]};
`

const StyledTextMediaTemplate3VideoContainer = styled.div`
  width: 66.2rem;
  height: 38.9rem;
  ${(props) => mediaStyles[props.$mediaAlignment]}
  position: relative;
  ${devices.tablet} {
    width: 67.2rem;
    height: 39.5rem;
    ${(props) => (props.$mediaAlignment !== 3 && props.$mediaAlignment !== 4 ? 'width: auto;' : '')}
  }
  ${devices.mobile} {
    flex: auto;
    width: 34.8rem;
    height: 20.1rem;
  }
`

const StyledTextMediaTemplate3VideoLayerImg = styled.img`
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 2rem;
  position: absolute;
  object-fit: cover;
  overflow: hidden;
  transform: scale(1.01);
  cursor: pointer;
  display: ${(props) => (props.$videoCoverImageVisible ? 'block' : 'none')};
`

const StyledTextMediaTemplate3VideoLayerIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 2rem;
  position: absolute;
  overflow: hidden;
`

const TextMediaTemplate3 = (props) => {
  console.log(props)
  const [videoCoverImageVisible, setVideoCoverImageVisible] = useState(true)
  const mediaLink = props?.field_component_asset?.field_media_oembed_video

  const onVideoCoverImageClicked = () => {
    setVideoCoverImageVisible(false)
  }

  return (
    <StyledTextMediaTemplate3Background $bgColor={props?.field_bg_color}>
      <StyledTextMediaTemplate3 $mediaAlignment={props?.field_alignment_2} className='text-media-temp3'>
        <StyledTextMediaTemplate3TextContainer $mediaAlignment={props?.field_alignment_2} className='text-media-temp3--text-container'>
          <StyledTextMediaTemplate3Text
            $textColor={props?.field_title_color}
            dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_title?.processed)}
            $flexDirection={props?.field_alignment_2}
            className='text-media-temp3--title'
          />
          <StyledTextMediaTemplate3Text
            $textColor={props?.field_long_desc_color}
            dangerouslySetInnerHTML={sanitizeHtml(props?.field_component_desc?.processed)}
            $flexDirection={props?.field_alignment_2}
            className='text-media-temp3--description'
          />
        </StyledTextMediaTemplate3TextContainer>
        <StyledTextMediaTemplate3VideoContainer $mediaAlignment={props?.field_alignment_2} className='text-media-temp3--video-container'>
          <StyledTextMediaTemplate3VideoLayerIframe
            src={props?.field_component_asset?.url || parseExtVideoLink(mediaLink)}
            as={props?.field_component_asset?.url ? 'video' : 'iframe'}
            controls
            autoplay='false'
            title={props?.field_component_image?.alt}
            preload='metadata'
            controlsList='nodownload nofullscreen noremoteplayback'
          />
          <StyledTextMediaTemplate3VideoLayerImg
            src={props?.field_component_image?.url}
            alt={props?.field_component_image?.alt}
            as='img'
            onClick={onVideoCoverImageClicked}
            $videoCoverImageVisible={videoCoverImageVisible}
          />
        </StyledTextMediaTemplate3VideoContainer>
      </StyledTextMediaTemplate3>
    </StyledTextMediaTemplate3Background>
  )
}

export default TextMediaTemplate3
