import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TagManager from 'react-gtm-module'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'

import {
  StyledDetailsConfirmationModal,
  StyledDetailsConfirmationModalHeaderColumn,
  StyledDetailsConfirmationModalHeader,
  StyledDetailsConfirmationModalContent,
  StyledDetailsConfirmationModalContentTitle,
  StyledDetailsConfirmationModalContentCenter,
  StyledDetailsConfirmationModalContentDateTime,
  StyledDetailsConfirmationModalContentDateTimeButton,
  StyledDetailsConfirmationModalButton,
  StyledDetailsConfirmationModalFailure,
  StyledCalendarIcon,
  StyledClockIcon
} from './styled'

import PrimaryButton from '../../Buttons/PrimaryButton'
// Actions
import appointmentsActions from '@takedapdt/biolife-core/src/Stores/Appointments/Actions'

const AppointmentDetailsConfirmModal = ({
  open,
  toggleModal,
  appointment,
  createNewAppointment,
  donor,
  center,
  toggleConfirmedAppointmentModal,
  createNewAppointmentLoading,
  createNewAppointmentSuccess,
  createNewAppointmentFailure,
  rescheduleAppointment,
  rescheduleAppointmentSuccess,
  rescheduleAppointmentLoading,
  rescheduleAppointmentFailure,
  toggleConfirmedCancellationModal,
  cancelAppointment,
  cancelAppointmentSuccess,
  cancelAppointmentLoading,
  cancelAppointmentFailure,
  appointmentDetails,
  loggedInUser,
  update,
  toggleRescheduleModal,
  setIsAppointmentRescheduling
}) => {
  const [confirmClicked, setConfirmClicked] = useState(false)
  const { t } = useTranslation('DashBoardConfirmAppointmentModal')
  const { isMobileWidth } = useScreenSize()

  const cancelledFrom = loggedInUser?.role === 'Donor' && (isMobileWidth ? 'MOBILE_WEB' : 'WEB')
  const createdFrom = loggedInUser?.role === 'Donor' && (isMobileWidth ? 'MOBILE_WEB' : 'WEB')
  const confirmAppointment = () => {
    setConfirmClicked(true)
    const finalAppointmentData = {
      startDateTime: appointment.startDateTime,
      centerSfid: appointment.centerSfid,
      appointmentType: appointment.appointmentType,
      donorSfid: donor.sfid,
      numberOfChildren: appointment.numberOfChildren || 0,
      createdFrom
    }
    const finalRescheduleAppointmentData = {
      rescheduledStartDateTime: appointment.startDateTime,
      donationCenter: appointment.centerSfid,
      donationType: appointment.appointmentType,
      donorSfid: donor.sfid,
      numberOfChildren: appointment.numberOfChildren || 0,
      createdFrom
    }

    if (!update) {
      createNewAppointment(finalAppointmentData)
    } else if (update === 'Reschedule') {
      rescheduleAppointment(appointment?.appointmentId || appointment?.id, finalRescheduleAppointmentData)
    } else if (update === 'Cancel') {
      cancelAppointment(appointmentDetails?.appointmentId || appointmentDetails?.id, cancelledFrom)
    }
  }

  useEffect(() => {
    if (open) {
      setConfirmClicked(false)
    }
  }, [open])

  useEffect(() => {
    if (!confirmClicked) {
      return
    } else if (!update && createNewAppointmentSuccess) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'appointment',
          appointmentStage: 'scheduled',
          appointmentId: createNewAppointmentSuccess.id,
          alternateCenterAppointment: appointment.alternateCenterSelected
        }
      })
      toggleModal()
      toggleConfirmedAppointmentModal()
    } else if (update === 'Reschedule' && rescheduleAppointmentSuccess) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'appointment',
          appointmentStage: 'rescheduled',
          appointmentId: rescheduleAppointmentSuccess.id
        }
      })
      toggleModal()
      toggleConfirmedAppointmentModal()
    } else if (update === 'Cancel' && cancelAppointmentSuccess) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'appointment',
          appointmentStage: 'canceled',
          appointmentId: cancelAppointmentSuccess.id
        }
      })
      toggleModal()
      toggleConfirmedCancellationModal()
    }
  }, [createNewAppointmentSuccess, rescheduleAppointmentSuccess, cancelAppointmentSuccess])

  return (
    <StyledDetailsConfirmationModal>
      <StyledDetailsConfirmationModalHeaderColumn>
        <StyledDetailsConfirmationModalHeader>
          {update === 'Cancel' ? t('cancelappointment') : t('detailsconfirmation')}
        </StyledDetailsConfirmationModalHeader>
      </StyledDetailsConfirmationModalHeaderColumn>
      <StyledDetailsConfirmationModalContent>
        <StyledDetailsConfirmationModalContentTitle>{t('appointmentdetails')}</StyledDetailsConfirmationModalContentTitle>
        <StyledDetailsConfirmationModalContentCenter>
          <strong>
            {`${
              update === 'Cancel' ? appointmentDetails?.name || appointmentDetails?.centerName : appointment.name || appointment.centerName
            }, ${
              update === 'Cancel'
                ? appointmentDetails?.stateCode || appointmentDetails?.centerStateCode
                : appointment.stateCode || appointment.centerStateCode
            }`}
          </strong>
        </StyledDetailsConfirmationModalContentCenter>
        <StyledDetailsConfirmationModalContentDateTime>
          <StyledCalendarIcon />
          {`${moment
            .parseZone(update === 'Cancel' ? appointmentDetails?.startDateTime : appointment.startDateTime)
            .format('MMMM D, YYYY')}`}
        </StyledDetailsConfirmationModalContentDateTime>
        <StyledDetailsConfirmationModalContentDateTime>
          <StyledClockIcon />
          {`${update === 'Cancel' ? appointmentDetails?.startTime : appointment.startTime && appointment.startTime}`}
        </StyledDetailsConfirmationModalContentDateTime>
        <StyledDetailsConfirmationModalContentDateTimeButton>
          <PrimaryButton
            variant='blue_underline'
            text={update === 'Cancel' ? t('keepappointment') : t('editdetails')}
            onClick={() => {
              toggleModal()
              update && update === 'Cancel' && setIsAppointmentRescheduling && setIsAppointmentRescheduling(false)
              update && update === 'Reschedule' && toggleRescheduleModal()
            }}
          />
        </StyledDetailsConfirmationModalContentDateTimeButton>
      </StyledDetailsConfirmationModalContent>
      <StyledDetailsConfirmationModalButton $failureState={!!createNewAppointmentFailure}>
        {// Display create appointment failure message if applicable
        confirmClicked &&
          (!update
            ? createNewAppointmentFailure && (
                <StyledDetailsConfirmationModalFailure>
                  <p>
                    <strong>{createNewAppointmentFailure.response.data.message}</strong>
                  </p>
                </StyledDetailsConfirmationModalFailure>
              )
            : update === 'Reschedule'
            ? rescheduleAppointmentFailure && (
                <StyledDetailsConfirmationModalFailure>
                  <p>
                    <strong>{rescheduleAppointmentFailure.response.data.message}</strong>
                  </p>
                </StyledDetailsConfirmationModalFailure>
              )
            : cancelAppointmentFailure && (
                <StyledDetailsConfirmationModalFailure>
                  <p>
                    <strong>{cancelAppointmentFailure.response.data.message}</strong>
                  </p>
                </StyledDetailsConfirmationModalFailure>
              ))}
        <PrimaryButton
          text={update === 'Cancel' ? t('confirmcancellation') : t('confirmdetails')}
          onClick={confirmAppointment}
          fluid={isMobileWidth}
          disabled={confirmClicked && (createNewAppointmentLoading || rescheduleAppointmentLoading || cancelAppointmentLoading)}
        />
      </StyledDetailsConfirmationModalButton>
    </StyledDetailsConfirmationModal>
  )
}

export default AppointmentDetailsConfirmModal
