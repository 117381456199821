import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import {
  StyledSubmitAppointment,
  StyledAppointmentRechedulerModal,
  StyledAppointmentRechedulerModalHeader,
  StyledFailureState,
  StyledButtonsColumn
} from './styled'
import HorizontalCalendar from '../HorizontalCalendar'
import TimeSlotPicker from '../TimeSlotPicker'
import AppointmentScheduleHeader from '../AppointmentScheduleHeader'
import AppointmentType from '../AppointmentType'
import PrimaryButton from '../../Buttons/PrimaryButton'
import { useScreenSize } from '../../../Contexts/ResponsiveContextProvider'

const AppointmentReschedule = ({
  open,
  toggleModal,
  appointment,
  setAppointment,
  handleAppointment,
  getSlotsForAppointmentType,
  centerSlots,
  centerSlotsFailure,
  centerSlotsLoading,
  centers,
  center,
  donor,
  loggedInUser,
  searchedCenters,
  getZipcodeSearchCenters,
  inquiry,
  apptCenter,
  toggleDetailConfirmationModal,
  appointmentDetails,
  setCancelledClicked,
  cancelledClicked,
  getAllCenters,
  appointmentState,
  getAppointmentCenter
}) => {
  const navigate = useNavigate()
  const { isMobileWidth } = useScreenSize()
  const [isNewSlotSelected, setIsNewSlotSelected] = useState(false)
  const { t } = useTranslation('ModifyAppointmentModal')
  const [isChangeLocationModal, setIsChangeLocationModal] = useState(false)

  const selectNewSlotHandler = (app) => {
    setIsNewSlotSelected(true)
    handleAppointment(app)
  }

  useEffect(() => {
    if (open) {
      setCancelledClicked(false)
    }
    getZipcodeSearchCenters(donor?.zipCode, true, 'disDate', donor?.donationCenter, true) // true corresponds to addNewDonorBonusInformation
  }, [open])

  useEffect(() => {
    if (donor && !appointment.startTime && appointment.startDateTime && appointment.appointmentType) {
      setIsNewSlotSelected(false)
      getSlotsForAppointmentType(
        appointment.startDateTime,
        null,
        null,
        appointment.numberOfChildren || 0,
        appointment.centerSfid,
        appointment.appointmentType,
        appointment.appointmentId,
        donor.sfid
      )
    }
  }, [appointment])

  useEffect(() => {
    if (appointment?.appointmentType !== 'DONATION') getZipcodeSearchCenters(donor?.zipCode, true, 'disdate', donor?.donationCenter, true) // true corresponds to addNewDonorBonusInformation
  }, [appointment.appointmentType])

  useEffect(() => {
    if (appointment?.centerSfid !== appointmentState?.centerSfid) {
      getAllCenters(appointment.centerSfid)
      getAppointmentCenter(appointment.centerSfid)
    }
  }, [appointment.centerSfid])

  const allowCenterEdit = () => {
    return center ? center.isDIS8Center : false
  }

  return (
    <StyledAppointmentRechedulerModal $isChangeLocationModal={isChangeLocationModal}>
      <StyledAppointmentRechedulerModalHeader>{t('scheduleAppointment')}</StyledAppointmentRechedulerModalHeader>
      <AppointmentScheduleHeader
        setAppointment={setAppointment}
        appointment={appointment}
        handleAppointment={handleAppointment}
        centers={appointment.appointmentType === 'DONATION' ? centers : searchedCenters}
        center={center}
        edit={allowCenterEdit}
        donor={donor}
        isChangeLocationModal={isChangeLocationModal}
        setIsChangeLocationModal={setIsChangeLocationModal}
        toggleModal={toggleModal}
        update
      />
      <AppointmentType
        donor={donor}
        appointment={appointment}
        handleAppointment={handleAppointment}
        edit
        centerSlotsFailure={centerSlotsFailure}
        inquiry={inquiry}
      />
      <HorizontalCalendar
        appointment={appointment}
        handleAppointment={handleAppointment}
        edit={loggedInUser?.role === 'Admin' ? true : false}
        centers={appointment.appointmentType === 'DONATION' ? centers : searchedCenters}
        donor={donor}
        center={center}
        centerSlotsFailure={centerSlotsFailure}
      />
      <TimeSlotPicker
        appointment={appointment}
        centerSlotsLoading={centerSlotsLoading}
        handleAppointment={selectNewSlotHandler}
        toggleModal={toggleModal}
        availableSlots={centerSlots || []}
        centerSlotsFailure={centerSlotsFailure}
      />

      <StyledSubmitAppointment>
        <StyledButtonsColumn>
          <PrimaryButton
            text={t('reschedule')}
            fluid={isMobileWidth}
            onClick={() => {
              toggleModal()
              toggleDetailConfirmationModal()
            }}
            disabled={!isNewSlotSelected}
          />
          <PrimaryButton
            text={t('cancelAppointment')}
            fluid={isMobileWidth}
            variant='blue_underline'
            onClick={() => {
              setCancelledClicked(true)
              toggleModal()
              toggleDetailConfirmationModal()
            }}
            disabled={!appointmentDetails.startDateTime || !appointmentDetails.startTime || !appointmentDetails.appointmentType}
          />
        </StyledButtonsColumn>
      </StyledSubmitAppointment>
    </StyledAppointmentRechedulerModal>
  )
}

export default AppointmentReschedule
