import React, { useEffect, useState } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
const UserActions = importer('Stores/Users/Actions')
const InputField = importer('Components/Forms/FormFields/InputField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const { devices } = importer('utils/styledComponentHelpers', null)
import { useTranslation } from 'react-i18next'
import { validate } from './validate'

const SocialLoginForm = ({ socialLogin, socialLoginLoading: loading, socialLoginSuccess, socialLoginFailure: error, uniqueFormData }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('SocialLoginForm')
  const [email, setEmail] = useState('')
  const initialData = {
    'Email Address': email
  }
  const { isMobileWidth } = useScreenSize()
  const fieldsWidths = isMobileWidth ? '90%' : '93%'
  const [saveClicked, setSaveClicked] = useState(false)

  /*
    dispatches socialLogin with email param and returns error if any
    param : email input field value
  */
  const handleSubmit = ({ 'Email Address': email }) => {
    setEmail(email)
    setSaveClicked(true)
    const trimmedEmail = email?.replace(/\s+/g, '')
    socialLogin(trimmedEmail)
  }

  /* redirects to success page on successful form submission */
  const handleNavigation = () => {
    setSaveClicked(false)
    navigate('/check-email?type=AccountUpdate')
  }

  useEffect(() => {
    if (saveClicked && socialLoginSuccess) {
      handleNavigation()
    }
  }, [socialLoginSuccess])

  const StyledSocialLoginDiv = styled.div`
    max-width: 524px;
    padding-bottom: 80px;
    .FormField {
      margin-bottom: 22px !important;
    }
    .ui.negative.message {
      font-size: 14px;
      margin-bottom: 32px;
      max-width: 93%;
      ${devices.mobile} {
        max-width: 90%;
      }
    }
  `

  const StyledBtnDiv = styled.div`
    display: inline;
    &:last-child {
      margin-left: 40px;
      ${devices.mobile} {
        margin: 0;
        button {
          margin-top: 40px;
        }
      }
    }
  `
  /* 
    renders the required component based on the component type 
    props-passed : component-type, fields for rendering forms data
  */
  function renderFormFields({ type, props }) {
    const { name = '', placeholder = '', fieldName = '', cta1Style = '', cta1 = {}, required, error } = props
    const formComponent = {
      form_field_component: (
        <>
          <Field name={fieldName}>
            {({ input, meta }) => {
              return (
                <InputField
                  required={required}
                  meta={meta}
                  {...input}
                  fieldLabel={name}
                  value={input.value}
                  placeholder={placeholder}
                  width={fieldsWidths}
                  isHtmlLabel
                />
              )
            }}
          </Field>
          {saveClicked && error && error.response && error.response.data && (
            <Message negative visible>
              <span>{error.response.data.code === 'DonorNotFound' ? t('accountNotFound') : error.response.data?.message}</span>
            </Message>
          )}
        </>
      ),
      form_cta_component: (
        <>
          {cta1?.uri?.includes('login') ? (
            <StyledBtnDiv>
              <PrimaryButton variant={cta1Style} text={cta1?.title} sendTo={cta1?.uri} fluid={isMobileWidth} />
            </StyledBtnDiv>
          ) : (
            <StyledBtnDiv>
              <PrimaryButton variant={cta1Style} text={cta1.title} fluid={isMobileWidth} loading={saveClicked && loading} type='submit' />
            </StyledBtnDiv>
          )}
        </>
      )
    }
    return formComponent[type]
  }

  return (
    <div className='main-container'>
      <StyledSocialLoginDiv>
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={initialData}
          validate={validate}
          render={({ handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {uniqueFormData?.map((item) => {
                  return renderFormFields({
                    type: item?.type,
                    props: {
                      name: item?.field_component_title?.processed,
                      labelType: item?.field_component_title?.format,
                      fieldName: item?.field_component_title_1,
                      placeholder: item?.field_component_title_2,
                      cta1Style: item?.field_cta_1_style,
                      cta1: item?.field_cta_1,
                      required: item?.field_status,
                      handleSubmit: { handleSubmit },
                      error
                    }
                  })
                })}
              </Form>
            )
          }}
        />
      </StyledSocialLoginDiv>
    </div>
  )
}

const mapStateToProps = (state) => ({
  socialLoginLoading: state.users?.socialLoginLoading,
  socialLoginSuccess: state.users?.socialLoginSuccess,
  socialLoginFailure: state.users?.socialLoginFailure
})

const mapDispatchToProps = (dispatch) => ({
  socialLogin: (email) => dispatch(UserActions.socialLogin(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginForm)
