import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Form, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Link, useNavigate, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import importer from '@takedapdt/biolife-core/src/importer'

const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
const UserActions = importer('Stores/Users/Actions')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const PrimaryTextArea = importer('Components/Inputs/PrimaryTextArea')
const CentersActions = importer('Stores/Centers/Actions')
const PublicContentActions = importer('Stores/PublicContent/Actions')
import { filterNonNomogramCentersPublicPage } from '../../../Helpers/Utils'

import validate from './validate'
import { values } from 'lodash'

const ContactUsForm = (props) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const {
    contactUsLoading: loading,
    contactUsError: error,
    contactUsSuccess,
    uniqueFormData,
    getAllStates,
    getAllCenters,
    states,
    centers,
    saveContactForm,
    saveContactFormSuccess,
    saveContactFormError,
    saveContactFormLoading
  } = props
  const navigate = useNavigate()
  const [saveClicked, setSaveClicked] = useState(false)
  const [selectedStateCode, setSelectedStateCode] = useState(null)
  const [selectedStateText, setSelectedStateText] = useState(null)
  const [otherLoginQuestionsSelected, setOtherLoginQuestionsSelected] = useState(false)
  const [stateOptions, setStateOptions] = useState([])
  const [centerOptions, setCenterOptions] = useState([])
  const { t, i18n } = useTranslation('ContactForm')
  const statusOptions = [
    { key: 'Potential BioLife Donor', value: 'Potential BioLife Donor', text: t('potentialBioLifeDonor') },
    { key: 'Former BioLife Donor', value: 'Former BioLife Donor', text: t('formerBioLifeDonor') },
    { key: 'Existing BioLife Donor', value: 'Existing BioLife Donor', text: t('existingBioLifeDonor') },
    { key: 'Not Applicable', value: 'Not Applicable', text: t('notApplicable') }
  ]

  const subjectOptions = [
    {
      key: 'LoginQuestions',
      category: t('loginQuestions'),
      text: t('loginQuestions'),
      header: true,
      subcategory: [
        { key: 'didNotRecieveVeriEmail', value: t('didNotRecieveVeriEmail'), text: t('didNotRecieveVeriEmail') },
        { key: 'iCanNotResetMyPassword', value: 'I can not reset my password', text: t('iCanNotResetMyPassword') },
        {
          key: 'iRecievedAnErrorMessageDuringLogin',
          value: 'I received an error message during login',
          text: t('iRecievedAnErrorMessageDuringLogin')
        },
        { key: 'otherLoginQuestions', value: 'Other Login Questions', text: t('otherLoginQuestions') }
      ]
    },
    { key: 'schedulingQuestions', value: 'Scheduling Questions', text: t('schedulingQuestions'), header: false },
    { key: 'customerServiceQuestions', value: 'Customer Service Questions', text: t('customerServiceQuestions'), header: false },
    { key: 'couponPaymentQuestions', value: 'Coupon/Payment Questions', text: t('couponPaymentQuestions'), header: false },
    {
      key: 'eligibilityToDonateQuestions',
      value: 'Eligibility to Donate Questions',
      text: t('eligibilityToDonateQuestions'),
      header: false
    },
    { key: 'giftCardQuestions', value: 'Gift Card Questions', text: t('giftCardQuestions'), header: false },
    { key: 'loyaltyProgramQuestions', value: 'Loyalty Program Questions', text: t('loyaltyProgramQuestions'), header: false },
    { key: 'otherQuestions', value: 'Other Questions', text: t('otherQuestions'), header: false }
  ]
  let contactUsData

  const optionalQuestionRef = useRef()

  useEffect(() => {
    getAllStates()
    getAllCenters()
    setCenterOptions([])
    if (states?.length > 0) {
      const sortedStates = states?.slice()?.sort((a, b) => a.description.localeCompare(b.description))
      states &&
        setStateOptions(
          sortedStates.map((item, index) => ({
            key: index,
            value: item.code,
            text: item.description
          }))
        )
    }
    optionalQuestionRef.current.style.display = 'none'
  }, [])

  useEffect(() => {
    const sortedStates = states?.slice()?.sort((a, b) => a.description.localeCompare(b.description))
    states &&
      setStateOptions(
        sortedStates.map((item, index) => ({
          key: index,
          value: { description: item.description, code: item.code, countrycode: item.countrycode },
          text: item.description
        }))
      )
  }, [states])

  useEffect(() => {
    const filteredCenters = centers?.filter(
      (center) => selectedStateCode?.stateCode === center.stateCode && selectedStateCode?.countrycode === 'US'
    )
    if (filteredCenters?.length > 0) {
      const filterRes = filterNonNomogramCentersPublicPage(filteredCenters)
      setCenterOptions(
        filterRes?.map((item, index) => ({
          key: index,
          value: item.sfid,
          text: (
            <label className='CenterLocationLabel'>
              <label className='LabelCenterName'>{item.name + ', ' + item.stateCode}</label>
              {/* space required after statecode and address line */}{' '}
              <label className='LabelCenterAddress'>
                {item.addressLine1} {item.addressLine2} {item.city}, {item.stateCode} {item.zipcode}
              </label>
            </label>
          )
        }))
      )
    } else {
      setCenterOptions([
        {
          key: 0,
          value: 'Other',
          text: 'Other'
        }
      ])
    }
  }, [selectedStateCode])

  useEffect(() => {
    if (saveClicked && saveContactFormSuccess) {
      handleNavigation()
    }
  }, [saveClicked, saveContactFormSuccess])

  const handleNavigation = (code) => {
    setSaveClicked(false)
    navigate('/contact-us-confirmation')
  }

  const handleSubmit = (values) => {
    contactUsData = {
      firstName: values['First Name'],
      lastName: values['Last Name'],
      email: values['Email Address'],
      phone: values['Phone Number'],
      status: values['Status'],
      subject: values['Subject'],
      description: values['Message'],
      state: selectedStateText,
      zipCode: values['zipCode'],
      center: values['Home Center'] === 'Other' ? null : values['Home Center'],
      customSubjectLine: values['Subject'] === 'Other Questions' ? values['Type Your Own Subject Line'] : null
    }
    const errors = validate(values)
    if (Object.keys(errors).length == 0) {
      setSaveClicked(true)
      saveContactForm(contactUsData)
    }
  }

  const handleStateChange = (value) => {
    setSelectedStateText(value.description)
    const state = states?.find((state) => state.description === value.description && state.countrycode === value.countrycode)
    setSelectedStateCode({ stateCode: state?.code, countrycode: state?.countrycode })
  }

  const onselectionchange = (name, value) => {
    if (optionalQuestionRef?.current) {
      optionalQuestionRef.current.style.display = name == 'Subject' && value == 'Other Questions' ? 'block' : 'none'
    }
    if (name === 'state') {
      handleStateChange(value)
    }
  }

  const optionMapper = (value) => {
    switch (value) {
      case 'Status':
        return statusOptions

      case 'Subject':
        return subjectOptions

      case 'state':
        return stateOptions

      case 'Home Center':
        return centerOptions
    }
  }

  function renderFormFields({ type, props }) {
    const {
      placeholder = '',
      name = '<p></p>',
      field_component_title_1: fieldName = '',
      field_cta_1_style = '',
      field_cta_1 = {},
      field_status,
      values,
      error
    } = props
    const formComponent = {
      form_field_component: (
        <>
          <Field name={fieldName}>
            {({ input, meta }) => {
              if (fieldName == 'Type Your Own Subject Line') {
                return (
                  <div ref={optionalQuestionRef}>
                    <InputField
                      required={field_status}
                      width={isMobileWidth ? '90%' : '63%'}
                      meta={meta}
                      {...input}
                      fieldLabel={name}
                      type={fieldName == 'Phone Number' ? 'number' : 'text'}
                      value={input.value}
                      placeholder={placeholder}
                      isHtmlLabel
                    />
                  </div>
                )
              } else if (props.field_input_type === 'textarea') {
                return (
                  <InputField
                    required={field_status}
                    width={isMobileWidth ? '90%' : '63%'}
                    meta={meta}
                    {...input}
                    fieldLabel={name}
                    value={input.value}
                    placeholder={placeholder}
                    height={200}
                    isHtmlLabel
                    isTextArea={true}
                  />
                )
              } else if (props.field_input_type === 'select' && name) {
                return (
                  <SelectField
                    required={field_status}
                    width={isMobileWidth ? '90%' : '63%'}
                    meta={meta}
                    input={input}
                    fieldLabel={name}
                    value={input.value}
                    placeholder={placeholder}
                    handleOnChange={(value) => {
                      onselectionchange(fieldName, value)
                      input.onChange(value)
                    }}
                    isHtmlLabel
                    options={optionMapper(fieldName)}
                    groupSelect={fieldName === 'Subject'}
                    className={fieldName}
                  />
                )
              }

              return (
                <InputField
                  required={field_status}
                  width={isMobileWidth ? '90%' : '63%'}
                  meta={meta}
                  {...input}
                  fieldLabel={name}
                  type={fieldName == 'Phone Number' ? 'number' : 'text'}
                  value={input.value}
                  placeholder={placeholder}
                  isHtmlLabel
                />
                /* TO-DO: Replace the above type value with value returned from Drupal once number is added as a type.
                  props.field_input_type to be replaced  */
              )
            }}
          </Field>
          {saveClicked && error && error.response && error.response.data && (
            <Message visible warning>
              <p>{error.response.data.message}</p>
            </Message>
          )}
        </>
      ),
      form_cta_component: (
        <>
          <p className='FormNote asterisk' key='requiredFieldNote'>
            <small class='HtmlLabelAsterisk'>*</small>
            {t('requiredField')}
          </p>
          {saveClicked && (
            <div>
              {saveClicked && saveContactFormError && (
                <Message warning visible>
                  <Message.Header>{t('serviceError')}</Message.Header>
                  <p>{t('errorSavingForm')}</p>
                </Message>
              )}
            </div>
          )}
          <PrimaryButton
            variant={field_cta_1_style}
            text={field_cta_1.title}
            style={{ marginRight: '10px' }}
            loading={saveClicked && loading}
            fluid={isMobileWidth}
          />
        </>
      )
    }
    return formComponent[type]
  }
  return (
    <div className='ContactUsForm main-container' id='ContactUsForm'>
      <FinalForm
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            {uniqueFormData.map((formComponent) => {
              return renderFormFields({
                type: formComponent.type,
                props: {
                  name: formComponent?.field_component_title?.processed,
                  fieldLabelType: formComponent?.field_component_title?.format,
                  field_component_title_1: formComponent?.field_component_title_1,
                  field_cta_1_style: formComponent?.field_cta_1_style,
                  field_cta_1: formComponent?.field_cta_1,
                  handleSubmit: { handleSubmit },
                  field_input_type: formComponent?.field_input_type,
                  field_status: formComponent?.field_status,
                  values,
                  error
                }
              })
            })}
          </Form>
        )}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  states: state.publicContent.States,
  centers: state.centers.donorCenters?.data,
  saveContactFormSuccess: state.publicContent.saveContactFormSuccess,
  saveContactFormError: state.publicContent.saveContactFormFailure,
  saveContactFormLoading: state.publicContent.saveContactFormLoading
})

const mapDispatchToProps = (dispatch) => ({
  saveContactForm: (formData) => dispatch(PublicContentActions.saveContactForm(formData)),
  getAllStates: () => dispatch(PublicContentActions.getAllStates()),
  getAllCenters: () => dispatch(CentersActions.getDonorCentersAll())
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm)
