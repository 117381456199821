import moment from 'moment'
import i18next from 'i18next'
import i18n from '../i18n'
import importer from '@takedapdt/biolife-core/src/importer'
const Config = importer('Config')

export const getOperationHours = (operation) => {
  if (operation.isClosed) {
    return i18n.t('common:closed')
  }
  return `${moment(operation.openingTime, 'h:mm:ss').format('h:mm A')} - ${moment(operation.closingTime, 'h:mm:ss').format('h:mm A')}`
}

export const getPlaroomHours = (operation) => {
  if (operation.isPlayroomClosed) {
    return i18n.t('common:closed')
  }
  return `${moment(operation.playroomOpeningTime, 'h:mm:ss').format('h:mm A')} - ${moment(operation.playroomClosingTime, 'h:mm:ss').format(
    'h:mm A'
  )}`
}

export const numberWithCommas = (x) =>
  x
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
/*Added function for getting number with commas with out decimals 
Checking if the input `x` is a valid number using isNaN and it will return original value 
Convert the input number to an integer using Math.floor to remove decimals
 Adding commas for thousands separation using a regular expression */
export const numberWithCommasWithOutDecimals = (number) => {
  if (isNaN(number)) {
    return number
  }
  const numberWithoutDecimals = Math.floor(number)
  return numberWithoutDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const chunkArray = (myArray, chunkSize) => {
  let index = 0
  const arrayLength = myArray.length
  const tempArray = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize)

    tempArray.push(myChunk)
  }

  return tempArray
}

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const getAge = (dateString) => {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export const setErrorMessageTitle = (msg) => {
  if (msg) {
    let titleIndex = msg.indexOf('.')
    const title = msg.slice(0, titleIndex)
    const message = msg.slice(titleIndex + 2)
    return { title, message }
  } else return { title: '', message: '' }
}

/**
  Utility function to filterout nomogram centers
  param {Array of Object} centers
  param {Object} donor (Provides data of default center of the loggedin user)
  param {Object} loggedInUser (Provides data on user's role)
  return {Array of Object} filtered centers
  */
export const filterNonNomogramCenters = (centers, donor, loggedInUser) => {
  let filterData = []
  if (loggedInUser?.role === 'Admin') {
    filterData = centers
    return filterData
  } else {
    centers?.forEach(function(obj) {
      if (
        (donor?.defaultCenter?.centerNum === obj?.centerNum && donor?.defaultCenter?.sfid === obj?.sfid) ||
        (obj?.isCenterHiddenFromDonors === null && donor?.defaultCenter?.centerNum !== obj?.centerNum)
      ) {
        if (obj.isCenterHiddenFromDonors === 'yes') {
          obj.name = splitCenterName(obj.name)
          filterData.push(obj)
        } else {
          filterData.push(obj)
        }
      }
    })
  }
  return filterData
}

/**
  Utility function to filterout public pages
  param {Array of Object} centers
  return {Array of Object} filtered centers
  */

export const filterNonNomogramCentersPublicPage = (centers) => {
  let filterData = []
  centers?.forEach(function(obj) {
    if (obj?.isCenterHiddenFromDonors === null) {
      filterData.push(obj)
    }
  })
  return filterData
}

/**
  Utility function to split the centername at '-' 
  param {String} centerName
  return {String} centerName
  */

export const splitCenterName = (centerName) => {
  return centerName?.split('--')[0]
}

export const mapOptionsByState = (list, centerOpsDonorSearch, loggedInUser) => {
  return new Promise((resolve, reject) => {
    try {
      const states = []
      if (list && list.length) {
        if (centerOpsDonorSearch) {
          return list.forEach(({ details, donationCenter }, index) => {
            details.name = loggedInUser?.role === 'Admin' ? details?.name : splitCenterName(details?.name)
            const foundIndex = states.findIndex((i) => i.state === details.state)
            if (foundIndex !== -1) {
              states[foundIndex].facilities.push({
                key: donationCenter,
                zipCode: details.zipcode,
                value: donationCenter,
                name: details.name,
                text: `${details?.name} Center ${details?.zipcode}`
              })
            } else {
              states.push({
                state: details.state,
                stateCode: details.stateCode,
                facilities: [
                  {
                    key: donationCenter,
                    zipCode: details.zipcode,
                    value: donationCenter,
                    name: details.name,
                    text: `${details?.name} Center ${details?.zipcode}`
                  }
                ]
              })
            }
            if (index === list.length - 1) {
              resolve(states)
            }
          })
        }
        return list.forEach((item, index) => {
          item.name = loggedInUser?.role === 'Admin' ? item?.name : splitCenterName(item?.name)
          const foundIndex = states.findIndex((i) => i.state === item.state)
          if (foundIndex !== -1) {
            states[foundIndex].facilities.push({
              key: item.sfid,
              stateCode: item.stateCode,
              zipCode: item.zipcode,
              value: item.sfid,
              name: item.name,
              text: `${item?.name} Center ${item?.zipcode}`,
              address: item.addressLine1,
              centerName: item.name,
              city: item.city,
              telephone: item.telephone,
              isSpanishSupported: item.isSpanishSupported
            })
          } else {
            states.push({
              state: item.state,
              stateCode: item.stateCode,
              facilities: [
                {
                  key: item.sfid,
                  stateCode: item.stateCode,
                  zipCode: item.zipcode,
                  value: item.sfid,
                  name: item.name,
                  text: `${item?.name} Center ${item?.zipcode}`,
                  address: item.addressLine1,
                  centerName: item.name,
                  city: item.city,
                  telephone: item.telephone,
                  isSpanishSupported: item.isSpanishSupported
                }
              ]
            })
          }
          if (index === list.length - 1) {
            resolve(states)
          }
        })
      } else resolve(list)
    } catch (error) {
      reject(error)
    }
  })
}
export const sortCentersAlphabetically = (data) => {
  data?.sort((a, b) => (a.state < b.state ? -1 : 1)) //sorting states alphabetically
  data?.map(({ facilities }) => facilities.sort((a, b) => (a.state < b.state ? -1 : 1))) //sorting centers alphabetically
  return data
}

// Get cookies from browser cookie storage
// const getCookies = () => {
//   let cookies = [];
//   const Storedcookiearr = document.cookie.split(';')
//   Storedcookiearr.forEach((item) => {
//     let [k,v] = item.split('=')
//     cookies.push(k.trim())
//   })
//   return cookies
// }

// Triger the Privecy Preference cookie settings if not accepted or rejected otherwise redirect to registration page
// export const openCookieSettingsModal = (history) => {
//   if(OneTrust) {
//     const cookies = getCookies()
//     if(cookies.indexOf('OptanonAlertBoxClosed') === -1) {
//       OneTrust.ToggleInfoDisplay(true)
//     } else history.push('/registration')
//   } else {
//     history.push('/registration')
//   }
// }

export const isMobileWeb = () => {
  if (window.navigator.userAgent.toLowerCase().indexOf('android') > -1 || window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
    return true
  } else return false
}

// Smooth scroll to the top of the page
export const ScrollToTop = () => {
  var isSafari = window.safari !== undefined
  if (isSafari) {
    // Safari does not support smooth scroll behaviour.. this scrollIntoView method is tested on Safari
    document.getElementById('react').scrollIntoView()
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

export const getArrayFromTextCTAGrid = (props) => {
  if (props && !Object.keys(props)) {
    return
  }
  const { contents = [], metadata = {} } = props || {}
  let gridCTAIndex = [0]
  const result = []
  let gridCollection = []
  let currentGridIndex = 0
  let divisionFound = false
  let backgroundColorSet = false
  if (contents && contents.length) {
    for (let [index, value] of contents.entries()) {
      let { type = '' } = value || {}
      if (type === 'text_cta_grid') {
        if (divisionFound) {
          divisionFound = false
          backgroundColorSet = false
          gridCTAIndex.push(0)
          gridCollection.push({
            type: 'text_cta_grid',
            grid: [],
            bgColor: '',
            field_txtgrid_template_type: value.field_txtgrid_template_type
          })
        }
        if (gridCTAIndex[currentGridIndex] == 0) {
          gridCTAIndex[currentGridIndex] = index - gridCollection.length
          gridCollection.push({
            type: 'text_cta_grid',
            grid: [],
            bgColor: '',
            field_txtgrid_template_type: value.field_txtgrid_template_type
          })
          if (!backgroundColorSet) {
            gridCollection[currentGridIndex].bgColor = value.field_bg_color
            backgroundColorSet = true
          }
        }
        gridCollection[currentGridIndex].grid.push(value)
      }
      if (type === 'text_icon_grid') {
        gridCollection[currentGridIndex].grid.push(value)
      } else {
        if (type !== 'text_cta_grid') {
          if (gridCTAIndex[currentGridIndex] && !divisionFound) {
            divisionFound = true
            currentGridIndex++
          }
          result.push(value)
        }
      }
    }
    for (let [index, value] of gridCTAIndex.entries()) {
      value && result.splice(value, 0, gridCollection[index])
    }
  }
  return { contents: result, metadata }
}

export const getClassFromPathname = (pathname) => {
  return `${pathname.substring(1).replace('/', '-')}`
}

export const getClassFromSlug = (slug) => {
  return `${slug?.replace('/', '-')}`
}

/**
 * This function converts a table to a list
 * data: string - The entire description markup eg. '<h4>Heading</h4><tr><td><p>Test Data One</p></td><td><p>Test Data Two</p></td></tr>
 * extractedTableDataHTML - The innerHTML of the <td> element eg. <p>Test Data One</p>
 * @returns {string} string containing the following:
 * dataExcludingTable - Rest of the string except the table eg. <h4>Heading</h4>
 * evenIndexedData - Table data at even indexes eg. <p>Test Data One</p>
 * oddIndexedData - Table data at odd indexes eg. <p>Test Data Two</p>
 */
export const convertTableToList = (data) => {
  let evenIndexedData = []
  let oddIndexedData = []
  let dataExcludingTable = ''
  if (data.includes('<td>')) {
    dataExcludingTable = data.split('<table>')[0]
    const tempElement = document.createElement('p')
    tempElement.innerHTML = data
    const tableData = tempElement.querySelectorAll('td')
    const extractedTableDataHTML = Array.from(tableData)
      ?.filter((data) => {
        return data.innerHTML !== '&nbsp;'
      })
      ?.map((data) => {
        return data.innerHTML
      })
    for (var i = 0; i < extractedTableDataHTML.length; i += 2) {
      evenIndexedData.push(extractedTableDataHTML[i])
      extractedTableDataHTML[i + 1] && oddIndexedData.push(extractedTableDataHTML[i + 1])
    }
    return [dataExcludingTable, ...evenIndexedData, ...oddIndexedData].toString().replaceAll(',', '')
  } else return data
}
/**
 * This function returns the path details
 * @returns {Object} obj which has the following properties
 * pathname: string - Full path name eg. /locations/ohio
 * segments: Array - Array of path segments eg. ['locations', 'ohio']
 * slugType: string - Slug Type eg. locations
 * pageRoute: string - Initial pageRoute eg. /locations
 * routeName: string - Initial routeName eg. locations
 */
export const getPathDetails = () => {
  let obj = {}
  obj.pathname = window.location.pathname

  // Extract the various segment from the path
  obj.segments = obj.pathname.split('/')

  // Extract the slugType from the path
  obj.slugType = obj.pathname.split('/')[1]

  // Extract the Initial pageRoute and routeName from the path
  obj.pageRoute = obj.segments.length > 1 ? `/${obj.segments[1]}` : ''
  obj.routeName = obj.segments.length > 1 ? obj.segments[1] : ''

  // Extract query params using URLSearchParams
  const queryParams = new URLSearchParams(window.location.search);
  obj.queryParams = {};
  for (let [key, value] of queryParams.entries()) {
    obj.queryParams[key] = value;
  }
  
  return obj
}
/**
 * Utility function to scroll to the top of the page
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export const getParagraphReadTime = ({ paras = '' }) => {
  const noHTMLString = paras.replace(/(<([^>]+)>)/gi, '')
  const NoOfWords = noHTMLString.split(' ')
  if (i18next.language === 'en-US') {
    // Dividing by 250 as The general adult population read 150-250 words per min
    return Math.ceil(NoOfWords.length / 250)
  } else {
    // Dividing by 218 as The general adult population read 150-250 words per min
    return Math.ceil(NoOfWords.length / 218)
  }
}

export const formatDate = (dateString) => {
  return moment(dateString).format('MMMM DD, YYYY')
}

export const calculateReadTime = (content) => {
  if(!content?.contents) return 0
  //check for textcomponent type and then concat title and description and get the time
  const textComponents = content.contents.filter((item) => item.type === 'text_component')

  const attributes = textComponents.map((component) => ({
    title: component?.field_component_title || {},
    description: component?.field_component_desc || {}
  }))

  const paras = attributes.map((attr) => `${attr.title.processed || ''} ${attr.description.processed || ''}`).join(' ')
  return getParagraphReadTime({ paras })
}

export const getTimeFromTimestamp = (timestampString) => {
  // Extract the time part from the timestamp string
  const timePart = timestampString.split('T')[1]
  // Parse the time part into hours and minutes
  const [hours, minutes] = timePart.split(':')

  // Determine if it's AM or PM
  const amOrPm = hours >= 12 ? 'PM' : 'AM'

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12

  // Format hours and minutes to ensure two digits
  const formattedMinutes = minutes

  // Return the formatted time as a string
  return formattedHours + ':' + formattedMinutes + ' ' + amOrPm
}

export const formatDateByMonth = (timestampString) => {
  return moment(timestampString).format('MMMM YYYY')
}

export const parseExtVideoLink = (mediaLink) => {
  let youtubeId = ''
  const mediaLinkObj = new URL(mediaLink)
  const mediaLinkPath = mediaLinkObj?.pathname
  const mediaLinkSearch = mediaLinkObj?.search
  const watchLinkSections = mediaLinkSearch?.split('=')
  const sharingLinkSections = mediaLinkSearch?.split('/')

  if (mediaLinkPath.includes('embed')) {
    return mediaLink
  } else if (mediaLink.includes('watch')) {
    youtubeId = watchLinkSections[1]
  } else {
    youtubeId = sharingLinkSections[1]
  }
  return `${Config.YOUTUBE_EMBED_BASE_URL}/${youtubeId}`
}

export const maskedPhoneNumber = ({ countryIsdCode, phoneNumber }) =>
  `(${countryIsdCode}) ${phoneNumber.replace(/\D/g, '').substring(0, 1) + '*******' + phoneNumber.replace(/\D/g, '').substring(8, 10)}`

export const commonMFAPasswordValidation = ({passwordField, errors, values})=>{
  const specialChars = /["!#$%&'()*+,-.\/\\:;<=>?@\[\]^_`{|}~]/;
  if (!specialChars.test(values[passwordField])) {
    errors[passwordField] = 'symbolRequired';
  }
  else if (values?.loginId && values[passwordField].toLowerCase().includes(values?.loginId?.toLowerCase())) {
    errors[passwordField] = 'shouldNotContainUserName';
  }
  else if (values?.firstName && values[passwordField].toLowerCase().includes(values?.firstName?.toLowerCase())) {
    errors[passwordField] = 'shouldNotContainFirstname';
  }
  else if (values?.lastName && values[passwordField].toLowerCase().includes(values?.lastName?.toLowerCase())) {
    errors[passwordField] = 'shouldNotContainLastName';
  }
  else if (values[passwordField].length < 8) {
    errors[passwordField] = 'passwordShouldHaveAtLeast8Char'
  }
  else if (!values[passwordField].match(/[a-z]/)) {
    errors[passwordField] = 'lowercaseRequired'
  }
  else if (!values[passwordField].match(/[A-Z]/)) {
    errors[passwordField] = 'uppercaseRequired'
  }
  else if (!values[passwordField].match(/[0-9]/)) {
    errors[passwordField] = 'numbersRequired'
  } 
}