import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Menu, Segment, Icon } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import StartupActions from '.././../../Stores/Startup/Actions'
const changeLanguageHelper = importer('Helpers/changeLanguage')

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const { getCssColorFromDrupalColorType } = importer('Constant/Utils', null)
const LoaderSpinner = importer('Components/Loaders/LoaderSpinner')

const DesktopHeader = ({ drupalNavData, topHeaderData, initializeApp, drupalNavDataLoading, topHeaderDataLoading }) => {
  const topNavBar = drupalNavData?.find((item) => item.type === 'header_component' && item.field_menu_type === 'top_header_menu')
  const topHeader = topHeaderData?.topHeaderMenu
  const [currentLanguage, setCurrentLanguage] = useState()

  useEffect(() => {
    const currentLang = window?.localStorage?.getItem('originalLng')
    if (currentLang) {
      setCurrentLanguage(currentLang)
    } else {
      setCurrentLanguage('en-US')
      window?.localStorage?.setItem('originalLng', 'en-US')
    }
  })

  /**
   * This function is called when the user clicks on the language button.
   */
  const changeLanguage = () => {
    if (currentLanguage === 'en-US') {
      internationalize('es-MX')
      setCurrentLanguage('es-MX')
    } else {
      internationalize('en-US')
      setCurrentLanguage('en-US')
    }
  }

  /**
   * This function is used to change the language of the application.
   * @param {string} lang
   */
  const internationalize = (lang) => {
    window?.localStorage?.setItem('originalLng', lang)
    changeLanguageHelper(lang)
    initializeApp('changeLanguage')
  }

  return (
    <>
      {!topHeaderDataLoading && !drupalNavDataLoading ? (
        <Segment className='DesktopHeaderContainer' style={{ background: getCssColorFromDrupalColorType(topNavBar?.field_bg_color) }}>
          <Segment.Group horizontal>
            <Segment style={{ background: getCssColorFromDrupalColorType(topNavBar?.field_bg_color) }}></Segment>
            <Menu style={{ background: getCssColorFromDrupalColorType(topNavBar?.field_bg_color) }}>
              {topHeader &&
                topHeader.length != 0 &&
                topHeader?.map((item, index) => {
                  return (
                    <Menu.Item key={index} name={item.title} className='NavbarMenuItem' to={item.href} as={Link} target={item.target}>
                      {item.title}
                    </Menu.Item>
                  )
                })}
              <Menu.Item className='translation-text'>
                <PrimaryButton
                  text={topNavBar?.field_cta_1?.title}
                  variant={topNavBar?.field_cta_1_style}
                  style={{
                    padding: '8px 16px',
                    fontSize: '14px'
                  }}
                  onClick={() => {
                    changeLanguage()
                  }}
                />
              </Menu.Item>

              {/* Search Icon Code commented out. */}
              {/* <div className='SearchIconContainer'>
              <Icon name='search' />
            </div> */}
            </Menu>
          </Segment.Group>
        </Segment>
      ) : (
        <LoaderSpinner backdrop="full" />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  drupalNavData: state.publicContent?.getDrupalNavigationSuccess?.contents,
  drupalNavDataLoading: state.publicContent?.getDrupalNavigationLoading,
  topHeaderData: state.publicContent.drupalMenuSuccess,
  topHeaderDataLoading: state.publicContent.drupalMenuLoading?.topHeaderMenu
})

const mapDispatchToProps = (dispatch) => ({
  initializeApp: (res) => dispatch(StartupActions.initializeApp(res))
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeader)
